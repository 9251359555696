import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MdModeEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { patientallList } from "../../Api_Redux/PatientalldetailsSlice";
import PaginatorDemo from "../../Component/Paginator";
import { InputText } from "primereact/inputtext";
import { Paginator } from "primereact/paginator";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import { DASHBOARD_ROUTE } from "../../_main/routeConstants";
import { IoMdAddCircle } from "react-icons/io";
import { useNavigate, useLocation } from "react-router-dom";
import { Checkbox } from 'primereact/checkbox';
import { Button } from "primereact/button";
import Modal from "react-bootstrap/Modal";
import { changeUsers } from "../../Api_Redux/ForgotSlice";
import Videofull from "../../asset/Image/Videofull.svg";
import { activateinactivate } from "../../Api_Redux/ActiveSlice";
import { INDIVIDUALPATIENTDETAILS_ROUTE, INDIVIDUALPATIENTDETAILSS_ROUTE, CARE_CHAT_ROUTE, STAFF_CHAT_ROUTE, EDIT_PATIENTS_ROUTE, STAFF_PATIENTS_ROUTE, PATIENTS_ROUTE, SEDIT_PATIENTS_ROUTE, PEDIT_PATIENTS_ROUTE } from "../../_main/routeConstants";
import { ToastContainer, toast } from "react-toastify";
import ResetPassword from "../../asset/Image/ResetPassword.svg";
import { patientId } from "../../Api_Redux/PatientidSlice";
import IconButton from '@mui/material/IconButton';
import { states, VoiceCallStatus } from "../../Component/States/states";
import { handleVoiceCall, handleDevice, deviceStatus } from "../../Api_Redux/VoiceCallSlice";
import { Device } from "@twilio/voice-sdk"; //twilio-client
import { videoUser } from "../../Api_Redux/VideoSlice";
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import Tooltip from "@mui/material/Tooltip";
import chat from "../../asset/Image/chat.svg";
import editmanagep from "../../asset/Image/editmanagep.svg";
import VisibilityIcon from '@mui/icons-material/Visibility';
// import { Button } from "primereact/button";
// import Modal from "react-bootstrap/Modal";
import { InputSwitch } from 'primereact/inputswitch'
import { Dropdown } from "primereact/dropdown";
function ManagePatient() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [totalCount, setTotalCount] = useState("");
  const [token, setToken] = useState("");
  const [device, setDevice] = useState(null);
  const [customFirst1, setCustomFirst1] = useState(0);
  const [customRows1, setCustomRows1] = useState(6);
  // const [checked1, setChecked1] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  // const [selectAll, setSelectAll] = useState(false)
  const [values, setValues] = useState("")
  const [individualpatient, setIndividualpatient] = useState("");
  // const [individual]
  const handleresetClose = () => setresetShow(false);
  const [staffId, setStaffId] = useState(0);
  const [patientEmail, setPatientEmail] = useState("");
  const [showreset, setresetShow] = useState(false);
  const [fullName, setFullName] = useState("");
  const [checked, setChecked] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [rowdata, setRowdata] = useState("");
  const [state1, setState1] = useState(states.CONNECTING);
  const [clicked, setClicked] = useState(false);
  const [bloodPressureName, setBloodPressureName] = useState("");
  const [bloodSugarName, setBloodSugarName] = useState("");
  const [pulseOxName, setPulseOxName] = useState("");
  const [bodyWeightName, setBodyWeightName] = useState("");
  const [Togglechecked, setToggleChecked] = useState(false);
  const [current_page, setCurrentPage] = useState(0)
 

  const { state } = useLocation();

  const Videolink = useSelector((state) => state.Videolink?.video?.data?.url);

  const isDisableCallIcon = useSelector((state) => state.VoiceCallSlice.voiceCallDetails.showPopup);

  const screenState = useSelector((state) => state.LoginSlice.login.data)

  const openModal = () => {
    setShowModal(true);
  };

  const handleClose1 = () => {
    // selectedData(null)
    setShowModal(false);
    // setShowStatusModal(false)
  };

  const navigateToChat = (rowData) => {
    { (screenState?.userType == 1) ? navigate(CARE_CHAT_ROUTE, { state: rowData }) : navigate(STAFF_CHAT_ROUTE, { state: rowData }) };
  };
  const callBackClick = (data) => {
    setClicked(false);
  };

  const handleClose2 = () => {
    setSelectedData(null)
    setShowStatusModal(!showStatusModal)
  };

  const allUser = useSelector(
    (state) => state.PatientalllistSlice?.patientall?.data?.allPatientList
  );

  useEffect(() => {
    const patId = {
      id: state?.userId
        ? state?.userId
        : localStorage.getItem("stateUserId")
        ? localStorage.getItem("stateUserId")
        : screenState.id,
    };

    dispatch(patientId(patId))
      .then((isSuccess) => {
        // debugger
        if (isSuccess.payload.status == 1) {
          setIndividualpatient(isSuccess.payload.data);
          setBloodPressureName(isSuccess.payload.data.bloodPressure);
          setBloodSugarName(isSuccess.payload.data.bloodSugar);
          setPulseOxName(isSuccess.payload.data.pulseOX);
          setBodyWeightName(isSuccess.payload.data.weightScale);

          localStorage.setItem(
            "stateUserId",
            JSON.stringify(isSuccess.payload.data.userId)
          );
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleCall = async (rowData) => {
    setRowdata(rowData);
    setState1(states.ON_CALL);
    const device = new Device(token);
    device.register();
    await device.connect({
      params: {
        To: rowData.phoneNumber,
      },
    })
    setDevice(device);
    const call = device._activeCall;


    call.on("accept", (call) => {
      console.log("Twilio accepted", call.status());
      dispatch(deviceStatus(VoiceCallStatus.CONNECTED));
    });

    call.on("ignore", (call) => {
      console.log("Twilio ignore", call.status());
    });

    call.on('cancel', (call) => {
      console.log('Twilio canceled.', call.status());
    });

    call.on('reject', (call) => {
      console.log('Twilio rejected.', call.status());
    });

    call.on('reconnecting', (twilioError) => {
      console.log('Twilio Connectivity error: ', twilioError);
    });

    call.on('reconnected', (call) => {
      console.log('Twilio regained connectivity.', call.status())
    });

    call.on('disconnect', (call) => {
      console.log('Twilio disconnected.', call.status());
      setTimeout(() => {
        console.log('dash The call has been disconnected. Timer')
        dispatch(deviceStatus(VoiceCallStatus.DISCONNECTED));
      }, 500);
    });
  }
  /*** END: Voice call New integration ***/

  const navigateToVideo = (rowData) => {
    const param = {
      userId: rowData.userId,
      meetingLink: "",
    };
    dispatch(videoUser(param));
    window.open(Videolink, "_blank");
  };
  const handleHangup = () => {
    setClicked(false);
    device.disconnectAll();
  };


  const handleSearch = (e) => {
    const patientParams =["all" ,{
      pageNo: 1,
      pageSize: customRows1,
      search: e.target.value,
    }] 
    dispatch(patientallList(patientParams));
  };




  const getPatientList = () => {
    // console.log("current_page11", current_page)
    // console.log("customFirst1*", customRows1)
    const params = ["all", {
      // pageNo: 1,
      pageNo: current_page,
      pageSize: customRows1,
      search: "",
    }] 
    dispatch(patientallList(params))
      .then((isSuccess) => {
        setShowStatusModal(false)
        console.log("isSuccess", isSuccess);
        if (isSuccess.payload.status == 1) {
          setTotalCount(isSuccess.payload.data.totalCount);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    // dispatch(patientallList(params));
    getPatientList();
  }, []);

  // const [patientId, setPatientId] = useState("")

  const handleActivebutton = () => {
    // console.log("customFirst1****", customFirst1)
    // console.log("customFirst2****", customRows1)

    // console.log("current_page",current_page)

    const params = {
      patientId: selectedData.userId,
      action: !selectedData.isActive,
    };
    dispatch(activateinactivate(params))
      .then((isSuccess) => {
        if (isSuccess.payload.status == 1) {
         
          setShowStatusModal(false)
          toast.success(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }
        getPatientList();
      })
      .catch((error) => {
        alert(error);
      });
  }

  const onCustomPageChange1 = (event) => {
    setCustomFirst1(event.first);
    setCustomRows1(event.rows);
    setCurrentPage(event.page+1)
    const patientParams = ["all", {
      pageNo: event.page + 1,
      pageSize: event.rows ? event.rows : 5,
      search: "",
    }]
    dispatch(patientallList(patientParams));
   
  };



  const openResetModal = (rowData) => {
    // console.log("resetStaff", rowData.email);
    setFullName(rowData.fullName);
    setPatientEmail(rowData.emailId);
    setresetShow(true);
  };


  const handleResetStaff = () => {
    const params = {
      email: patientEmail,
    };
    // console.log("staffEmail====>", params)
    dispatch(changeUsers(params))
      .then((isSuccess) => {
        if (isSuccess.payload.status == 1) {
          toast.success(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          setTimeout(() => { }, 1000);
          // setShow(false);
          setresetShow(false);
        } else {
          toast.error(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          setTimeout(() => { }, 1000);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };




  const actionBodyTemplate1 = (rowData, fieldProperties) => {
    return (
      <React.Fragment>
        {/* <Checkbox
          onChange={(e) => handleCheckboxChange(e, rowData, fieldProperties.rowIndex)} checked={rowData.isActive}
        ></Checkbox> */}

        <InputSwitch checked={rowData.isActive} onChange={(e) =>handleCheckboxChange(e, rowData, fieldProperties.rowIndex)} />
      </React.Fragment>
    );
  };

  const handleCheckboxChange = (e, data, index) => {
    // debugger

    e.preventDefault();
    setSelectedData(data)
    setShowStatusModal(true)
  };

  const goback = () => {
    navigate(DASHBOARD_ROUTE);
  };

  const getNameRecords = () => {
    return (
      <React.Fragment>
        <div>
          <div>{allUser[0].fullName}</div>
          <div>{allUser[0].phoneNumber}</div>
          <div>{allUser[0].emailId}</div>
        </div>
      </React.Fragment>
    );
  };


  const navigatePatientInfo = (user) => {
    // debugger
    const param = {
      id:user.userId
    }
    dispatch(patientId(param))
    .then((isSuccess) => {
      // debugger
      if (isSuccess.payload.status == 1) {
        // console.log("isSuccessisSuccess", isSuccess.payload.data);
        setIndividualpatient(isSuccess.payload.data);
        if (screenState?.userType == 1) {

          navigate(EDIT_PATIENTS_ROUTE, { state: isSuccess.payload.data });
        } else if (screenState?.userType == 2) {
          navigate(SEDIT_PATIENTS_ROUTE, { state: isSuccess.payload.data });
        } else {
          navigate(PEDIT_PATIENTS_ROUTE, { state: isSuccess.payload.data });
        }
        localStorage.setItem(
          "stateUserId",
          JSON.stringify(isSuccess.payload.data.userId)
        );
      }
    })
    .catch((err) => console.log(err));
  
  };

  
  var x = localStorage.getItem("userType");

  const pnavigateToEdit = (user) => {

    // setValues(e.data)
    const param = {
      id:user.userId
    }
    dispatch(patientId(param))

    navigate(INDIVIDUALPATIENTDETAILS_ROUTE, { state: user });
    { x == 2 && (navigate(INDIVIDUALPATIENTDETAILSS_ROUTE, { state: user })) }
  };

  const navigateToEdit = () => {
    { (screenState?.userType == 1) ? navigate(PATIENTS_ROUTE) : navigate(STAFF_PATIENTS_ROUTE) }
  };

  const actionBodyTemplate = (rowData) => {
    return (

      <React.Fragment>
        {/* <Tooltip>
          <IconButton><VisibilityIcon onClick={(e) => pnavigateToEdit(rowData)} /></IconButton>
        </Tooltip> */}

        <div>
          <Tooltip
            title={<p style={{ fontSize: "10px" }}>Edit User</p>}
            placement="right-start"
            arrow
          >
            <img className="ms-edit1" onClick={(e) =>  navigatePatientInfo(rowData) } src={editmanagep} alt="edit" />
          </Tooltip>
        </div>

        {/* <Tooltip
          title={<p style={{ fontSize: "10px" }}>Make a Call</p>}
          placement="bottom"
          arrow
        >
          <IconButton style={{ marginLeft: "-0.5em" }} onClick={(e) => { e.stopPropagation(); handleCall(rowData) }} disabled={isDisableCallIcon}>{(isDisableCallIcon == true) ? <WifiCalling3Icon color="disabled" sx={{ fontSize: 27 }} /> : <WifiCalling3Icon color="success" sx={{ fontSize: 27 }} />}</IconButton>
        </Tooltip> */}
        {/* <Tooltip
          title={<p style={{ fontSize: "10px" }}>Chat</p>}
          placement="bottom"
          arrow
        >
          <img
            className="vedioicon"
            src={chat}
            // onClick={() => navigateToChat(rowData)}
            onClick={(e) => { e.stopPropagation(); navigateToChat(rowData); }}
            style={{ marginRight: "0.3em", marginLeft: "0.3em" }} />
        </Tooltip> */}
        {/* <Tooltip
          title={<p style={{ fontSize: "10px" }}>Make a Video Call</p>}
          placement="bottom"
          arrow
        >
          <img
            className="vedioicon"
            style={{ marginRight: "0.3em", marginLeft: "0.3em" }} 
            src={Videofull}
            // onClick={() => navigateToVideo(rowData)}
            onClick={(e) => { e.stopPropagation(); navigateToVideo(rowData); }} />
        </Tooltip> */}
        <Tooltip
          title={<p style={{ fontSize: "10px" }}>Reset Password</p>}
          placement="bottom"
          arrow
        >
          <img
            className="ms-reset1"
            src={ResetPassword}
            onClick={(e) => { e.stopPropagation(); openResetModal(rowData) }}
          // onClick={(e) => { e.stopPropagation(); navigateToVideo(rowData); } }
          />
        </Tooltip>


      </React.Fragment>
    );
  };


  const representativeBodyTemplate = (rowData) => {
    return (
      <>

        <span onClick={(e) => pnavigateToEdit(rowData)}
          style={{ color: "#007BFF" }}>{rowData.fullName}
          <div style={{color: "#495057"}}>{rowData.phoneNumber}</div>
          <div className="overflow-ellipsis1" style={{color: "#495057"}}>{rowData.emailId}</div></span>
      </>
    );
  };

  const template1 = {
    layout:
      "PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport",
    PrevPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-2">Pre</span>
        </button>
      );
    },
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
       
   
        { label: 5, value: 5 },
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: "100 and above", value: 5000 }
      ];

      return (
        <React.Fragment>
       
          <Dropdown
            
            value={options.value}
         
            options={dropdownOptions}
            onChange={options.onChange}
            placeholder="Choose"
          />
        </React.Fragment>
      );
    },
    NextPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-2">Next</span>
        </button>
      );
    },
  };


  // const pnavigateToEdit = (e) => {
  //   // e.stopPropagation()
  //   // setValues(e.data)
  //   const param = {
  //     id: e.data.userId
  //   }
  //   console.log("event",e)
  //   // debugger
  //   // if(e.originalEvent.target.textContent !== "STATUS"){
  //   dispatch(patientId(param))
  //   navigate(INDIVIDUALPATIENTDETAILS_ROUTE, { state: e.data });
  //   // }
  // };   




  return (
    <div>


      <Modal show={showreset} onHide={handleresetClose} animation={false}>
        <Modal.Header className='popupclose-btn' closeButton></Modal.Header>
        <Modal.Body >Do you want to reset "{fullName}" password?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleresetClose}>
            No
          </Button>
          <Button variant="primary" onClick={handleResetStaff}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal
        show={showStatusModal}
        onHide={handleClose2}
        animation={false}
      // centered
      >
        <Modal.Header
          className="popupclose-btn"
          closeButton
        ></Modal.Header>
        <Modal.Body style={{ fontSize: "20px" }}>
          Do You Want to {selectedData?.isActive == false ? 'Activate' : 'InActivate'} User ?
        </Modal.Body>
        <Modal.Footer className="footer-delete">
          <button className="modal-close" variant="secondary" onClick={handleClose2}>
            Close
          </button>
          <Button variant="primary" onClick={handleActivebutton}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>





      <Modal
        show={showModal}
        onHide={handleClose1}
        animation={false}
      // centered
      >
        <Modal.Header
          className="popupclose-btn"
          closeButton
        ></Modal.Header>
        <Modal.Body style={{ fontSize: "20px" }}>
          Do you want to Delete
        </Modal.Body>
        <Modal.Footer className="footer-delete">
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <div style={{ flexDirection: "row", display: "flex" }}>
        <Typography
          variant="h5"
          style={{ marginTop: "0.6em" }}
          className="header-message"
          onClick={goback}
        >
          <ArrowBackIcon
            style={{ cursor: "pointer" }}
            fontSize={"25"}
          ></ArrowBackIcon>
        </Typography>
        <div className="ml-title">MANAGE PATIENT  
          {/* <Checkbox  onChange={e => setChecked(e.checked)} checked={checked} onClick={openModal} /> */}
        </div>
      </div>
      <div style={{ textAlign: "end", marginTop: "-3.5em", marginRight:"6em" }}>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />

          <InputText onChange={handleSearch} placeholder="Search" />
        </span>
      </div>
      <div style={{textAlign:"end", marginTop:"-3.5em"}}>
          <div>
            <span>
              <IoMdAddCircle
              style={{marginRight:"1em"}}
                size={40}
                className='dashbadd-patient'
                onClick={navigateToEdit} />
            </span>
          </div>
          <div className="add-patient">ADD PATIENT</div>
        </div>


      <div style={{ marginTop: "10px" }}>
        {/* <InputSwitch checked={rowClick} onChange={(e) => setRowClick(e.value)} /> */}
        <DataTable
          value={allUser}
          style={{ cursor: "pointer", fontSize: "13px" }}
          scrollable
          // scrollHeight="1000px"

          scrollHeight="700px"
        >
          <Column field="fullName"  header="NAME" sortable body={representativeBodyTemplate} ></Column>
          {/* <Column
            field="bodyTraceIMEI"
            header="BLOODPRESSUREIMEI"
            sortable
            style={{ justifyContent: "right" }}
          ></Column> */}

          <Column field="continuaBloodPressureIMEI" header="BLOOD PRESSURE IMEI"  style={{ justifyContent: "right" }} sortable></Column>
          <Column field="continuaBloodSugarIMEI" header="BLOOD SUGAR IMEI"  style={{ justifyContent: "right" }} sortable></Column>
          <Column
            field="iGlucoseBloodPressureIMEI"
            header="iGLUCOSE BLOOD PRESSURE IMEI"
            style={{ justifyContent: "right" }}
            sortable
          ></Column>
          <Column field="iGlucoseIMEI" header="iGLUCOSE BLOOD SUGAR IMEI"  style={{ justifyContent: "right" }} sortable></Column>

          {/* <Column
            field="bodyTraceWeightScaleIMEI"
            header="WEIGHTIMEI"
            style={{ justifyContent: "right" }}
            sortable
          ></Column>
          <Column field="iGlucoseIMEI" header="GLUCOSEIMEI"  style={{ justifyContent: "right" }} sortable></Column>
          <Column
            field="iGlucosePulseOxIMEI"
            header="PULSEOXIMEI"
            sortable
            style={{ justifyContent: "right" }}
          ></Column>
          <Column
            field="bionimeIMEI"
            header="GLUCOSEBIONIMEIMEI"
            sortable
            style={{ justifyContent: "right" }}
          ></Column> */}
          {/* <Column
            field="check"
            header="In Activate"
            body={actionBodyTemplate1}
            style={{ justifyContent: "right" }}
          ></Column> */}
          {/* <Column field="createrName" header="CREATED BY" style={{ justifyContent: "right" }} sortable></Column> */}
          {/* Checkbox */}
          <Column
            style={{ justifyContent: "right" }}
            field="isActive"
            header="Active"

            // header="STATUS"
            body={actionBodyTemplate1}
            sortable
          ></Column>
           {/* Checkbox */}
          <Column
           style={{ justifyContent: "center" }}
            header="ACTION"
            body={actionBodyTemplate}
          >

          </Column>
        </DataTable>
      </div>
      <div className="paginator">
        <Paginator
          template={template1}
          first={customFirst1}
          rows={customRows1}
          totalRecords={totalCount}
          onPageChange={onCustomPageChange1}
     
        ></Paginator>
      </div>
    </div>
  );
}

export default ManagePatient;







