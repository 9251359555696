import React, { useState, useEffect, useRef } from "react";
import { Card } from "primereact/card";
import "./styles.css";
import edit_icon from "../../asset/Image/edit_icon.png";
import del_icon from "../../asset/Image/del_icon.png";
import PatientMedications from "../../Component/Table/patientmediaction";
import IcdTable from "../../Component/Table/icdtable";
import bpnew from "../../asset/Image/bpnew.svg";
import weightnew from "../../asset/Image/weightnew.svg";
import pulsenew from "../../asset/Image/pulsenew.svg";
import glucosebionimenew from "../../asset/Image/glucosebionimenew.svg";
import BarChartGluc from "../../Component/Barchart/index_glucose";
import BarChartPulseDemo from "../../Component/Barchart/index_pulseoxd";
import BarChartbpiGlucose from "../../Component/Barchart/index_bpiglucose";
import BarChartWeightDemo from "../../Component/Barchart/index_weightscal";
import LineChartDemo from "../../Component/LineChart/index";
import SelectButtonDemo from "../../Component/Tabbutton/index";
import SelectBPButtonGraphDemo from "../../Component/Tabbutton/index_bpfilter";
import SelectWeightButtonGraphDemo from "../../Component/Tabbutton/index_weightfilter";
import SelectPulseButtonGraphDemo from "../../Component/Tabbutton/index_pulsefilter";
import SelectButtonRpmGraphDemo from "../../Component/Tabbutton/index_rpm";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import etickicon from "../../asset/Image/etickicon.svg";
import editcard from "../../asset/Image/editcard.svg";
import remove from "../../asset/Image/remove.svg";
import DataTableBasicDemo from "../../Component/Table/index";
import PatientUpdate from "../../Component/Table/patientupdate";
import { getdoctornotes } from "../../Api_Redux/GetDoctorNotesSlice";
import { deletedoctornotes } from "../../Api_Redux/DoctorNotesDeleteSlice";
import {deletemedication } from "../../Api_Redux/MedicationDeleteSlice";
import {deletediagnosis} from "../../Api_Redux/DiagnosisDeleteSlice";
import { doctornotes } from "../../Api_Redux/DoctorNotesSlice";
import { updatemedication } from "../../Api_Redux/MedicationSlice";
import { updatediagnosis } from "../../Api_Redux/DiagnosisSlice";


import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { deletepatient } from "../../Api_Redux/PatientDeleteSlice";
import { Accordion, AccordionTab } from "primereact/accordion";
import { IoMdAddCircle } from "react-icons/io";
import { getotherdetailslist } from "../../Api_Redux/PatientGetOtherDetailsListSlice";
import { getotherdetails } from "../../Api_Redux/PatientGetOtherDetailsSlice";
import { updateotherdetails } from "../../Api_Redux/PatientUpdateOtherDetailsSlice";
import { getprofiledetail } from "../../Api_Redux/GetProfileSlice";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  EDIT_PATIENTS_ROUTE,
  PEDIT_PATIENTS_ROUTE,
  SEDIT_PATIENTS_ROUTE,
  MANAGEPATIENT_ROUTE,
  DASHBOARD_ROUTE
} from "../../_main/routeConstants";
import Avatar from "@mui/material/Avatar";
import Modal from "react-bootstrap/Modal";
import Tooltip from "@mui/material/Tooltip";
import linkicon from "../../../src/asset/Image/linkicon.svg";
import moment from "moment";
import dummyprofile from "../../asset/Image/dummyprofile.svg";
import Carousel from "../../Component/Carousel/Carousel";
import { Calendar } from "primereact/calendar";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Timer from "../../Component/Timer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { gettimerdetail } from "../../Api_Redux/TimerGetSlice";
import DataTableWeightDemo from "../../Component/Table/index_weight";
import { getIGlucose } from "../../Api_Redux/IGlucoseSlice";
import LineChartPulseDemo from "../../Component/LineChart/index_pulse";
import LineChartWeightDemo from "../../Component/LineChart/index_bodyweight";
import DataTablePulseDemo from "../../Component/Table/index_pulse";
import LineChartSugarDemo from "../../Component/LineChart/index_sugar";
import DataTableSugarDemo from "../../Component/Table/index_sugar";
import BarChartOmronDemo from "../../Component/Barchart/index_omron";
import { getFilteredData } from "../../Api_Redux/GetFilteredDataSlice";
import timericon from "../../asset/Image/timericon.png";
import done from "../../../src/asset/Image/Done.png";
import TimerCCM from "../../Component/Ccm_timer";
import { getccmtimerdetail } from "../../Api_Redux/TimerccmGetSlice";
import { getNewReadings } from "../../Api_Redux/PatientGetNewReadingsSlice";
import exportpdf from "../../asset/Image/exportpdf.svg";
import SelectButtonSugarGraphDemo from "../../Component/Tabbutton/index_Sugarfilter";
import { states, VoiceCallStatus } from "../../Component/States/states";
import { Device } from "@twilio/voice-sdk"; //twilio-client
import {
  CHAT_ROUTE,
  STAFF_MANAGEPATIENT_ROUTE,
  CHANGEPASSWORD_ROUTE,
  CARE_CHAT_ROUTE
} from "../../_main/routeConstants";
import { videoUser } from "../../Api_Redux/VideoSlice";
import { patientId } from "../../Api_Redux/PatientidSlice";
import Spinner from "react-bootstrap/Spinner";
import { BsGenderFemale } from "react-icons/bs";
import { BsGenderMale } from "react-icons/bs";
import {
  handleVoiceCall,
  handleDevice,
  deviceStatus,
} from "../../Api_Redux/VoiceCallSlice";
import WifiCalling3Icon from "@mui/icons-material/WifiCalling3";
import IconButton from "@mui/material/IconButton";
import { CALLING_API_URL } from "../../_main/constants";
import { useLocation } from "react-router-dom";
import { is } from "date-fns/locale";


const list = [<PatientUpdate />];

function Patientdashboard(props) {
  const [timercount, setTimerValue] = useState(null);
  const [sugarLevel, setSugarLevel] = useState({})
  const Videolink = useSelector((state) => state.Videolink?.video?.data?.url);
  const isDisableCallIcon = useSelector(
    (state) => state.VoiceCallSlice.voiceCallDetails.showPopup
  );
  

  const { state } = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();


 
  const [show, toggleShow] = React.useState(false);
  const [show1, toggleShow1] = React.useState(false);
  const [note, setNote] = useState("");
  const [name, setName] = useState("");
  const [symt, setSymt] = useState("");
  const [plan, setPlan] = useState("");
  const [inter, setInter] = useState("");
  const [out, setOut] = useState("");
  const [goal, setGoal] = useState("");
  const [addnote, setAddnote] = useState("");
  const [dose, setDose] = useState("");
  const [fre, setFre] = useState("");
  const [des, setDes] = useState("");
  const [icd, setIcd] = useState("");
  const [icddesc, seticddesc] = useState("");
  const [dateCreate, setDateCreate] = useState([]);
  const [medicationList, setMedictionList] = useState([]);
  const [docNoteList, setdocNoteList] = useState([]);
  const [icdList, seticdList] = useState([]);
  const [graphFilter, setGraphFilter] = React.useState("RPM");
  const [glucoseChartFiler, setGlucoseChartFilter] = React.useState("Scatter");
  const [bpChartFiler, setbpChartFilter] = React.useState("Bar");
  const [weightChartFiler, setweightChartFilter] = React.useState("Scatter");
  const [pulseChartFilter, setPulseChartFilter] = React.useState("Bar");
  const [patientDetails, setPatientDetails] = useState([]);
  const [patientotherDetail, setPatientOtherDetail] = useState([]);
  const [noteId, setNoteId] = useState("");
  const [medId,setMedId] = useState("");
  const [diagnId,setDiagnId] = useState("");

  const [deleteId, setDeleteId] = useState(0);
  const [deleteMedId, setDeleteMedId] = useState(0)
  const [deleteDiagId, setDeleteDiagId] = useState(0)
  const [updated, setUpdated] = useState(false);
  const [medupdated, setMedUpdated] = useState(false);
  const [diagnupdated, setDiagnUpdated] = useState(false);


  const [profileDetail, setProfileDetail] = useState("");
  const [shows, setShow] = useState(false);
  const [showsmed, setShowmed] = useState(false);
  const [showsdiag, setShowdiag] = useState(false);

  const [showDetails, setShowDetails] = useState(false);
  const [fullName, setFullName] = useState("");
  const [IGlucoseStartDate, setIGlucoseStartDate] = useState(null);
  const [IGlucoseEndDate, setIGlucoseEndDate] = useState(null);
  const [sugarStartDate, setSugarStartDate] = useState(null);
  const [sugarEndDate, setSugarEndDate] = useState(null);
  const [pulseStartDate, setPulseStartDate] = useState(null);
  const [pulseEndDate, setPulseEndDate] = useState(null);
  const [latestGlucoseValue, setLatestGlucoseValue] = useState(0);
  const [latestSystolicValue, setLatestSystolicValue] = useState(0);
  const [latestDiastolicValue, setLatestDiastolicValue] = useState(0);
  const [latestPulseOxValue, setLatestPulseOxValue] = useState(0);
  const [token, setToken] = useState("");
  const [state1, setState] = useState(states.CONNECTING);
  const [device, setDevice] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [rowdata, setRowdata] = useState("");
  const [bloodPressureName, setBloodPressureName] = useState("");
  const [bloodSugarName, setBloodSugarName] = useState("");
  const [pulseOxName, setPulseOxName] = useState("");
  const [bodyWeightStartDate, setBodyWeightStartDate] = useState(null);
  const [bodyWeightEndDate, setBodyWeightEndDate] = useState(null);
  const [bodyTraceBpStartDate, setBodyTraceBpStartDate] = useState(null);
  const [bodyTraceBpEndDate, setBodyTraceBpEndDate] = useState(null);
  const [latestBodyWeightValue, setLatestBodyweightValue] = useState(0);
  const [bodyWeightName, setBodyWeightName] = useState("");
  const [bodyWeightData, setBodyWeightData] = useState("");
  const [bodyTraceBpData, setBodyTraceBpData] = useState("");
  const handleClose1 = () => setShow(false);
  const handleClose3 = () => setShowmed(false);
  const handleClose4 = () => setShowdiag(false);

  const handleShow1 = () => setShow(true);
  const [callbackMinutes, setCallbackMinutes] = React.useState(0)
  const [selectList, setSelectList] = useState(null);
  const [date, setDate] = useState("")
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdatingdiagn, setIsUpdatingdiagn] = useState(false);
  const [medicationListdata, setMedicationList] = useState([])



  const getpatientTimerData = useSelector(
    (state) => state.TimerGetSlice?.getdetail?.data
  );
  const loading = useSelector((state) => state.TimerGetSlice?.loading);


  const getpatientccmTimerData = useSelector(
    (state) => state.TimerccmGetSlice?.getdetail?.data
  );
  const loading2 = useSelector((state) => state.TimerccmGetSlice?.loading);

  const [showModal, setShowModal] = useState(false);
  const loginCount = useSelector((state) => state.LoginSlice?.login?.data?.loginCount)
  const [showStatusModal, setShowStatusModal] = useState(true);
  const [filterValue, setFilterValue] = useState("Last 30 days");
  const [dataItemName, setDataItemName] = useState("");
  const [promptAlert, setShowprompt] = useState(false);
  const [bloodSugarBionimeData, setBloodSugarBionimeData] = useState("");
  const [iglucoseBloodSugarData, setIglucoseBloodSugarData] = useState("");
  const [ContinuaBloodSugarData, setContinuaBloodSugarData] = useState("");
  const [bloodPressureOmronData, setBloodPressureOmronData] = useState("");
  const [bloodPressureIglucoseData, setBloodPressureIglucoseData] = useState("");
  const [bloodPressureContinuaData, setBloodPressureContinuaData] = useState("");
  const [pulseData, setPulseData] = useState(""); 
  const [individualpatient, setIndividualpatient] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [date12, setDate12] = useState(null);

  const locationState = useLocation();

  const dateTemplates = (date) => {
    if (date.day > 10 && date.day < 15) {
        return (
            <strong style={{ textDecoration: 'line-through' }}>{date.day}</strong>
        );
    }

    return date.day;
  } 

  useEffect(() => {
    const patId = {
      id: state?.userId
        ? state?.userId
        : localStorage.getItem("stateUserId")
          ? localStorage.getItem("stateUserId")
          : screenState.id,
    };



    dispatch(patientId(patId))
      .then((isSuccess) => {
        // console.log("herereisSuccess", isSuccess)
        if (isSuccess.payload.status == 1) {
          setIndividualpatient(isSuccess.payload.data);
          setSugarLevel({ max: isSuccess.payload.data.bloodSugarMax, min: isSuccess.payload.data.bloodSugarMin })
          setBloodPressureName(isSuccess.payload.data.bloodPressure);
          setBloodSugarName(isSuccess.payload.data.bloodSugar);
          setPulseOxName(isSuccess.payload.data.pulseOX);
          setBodyWeightName(isSuccess.payload.data.weightScale);

          localStorage.setItem(
            "stateUserId",
            JSON.stringify(isSuccess.payload.data.userId)
          );
        }
      })
      .catch((err) => console.log(err));

    const timerValue = JSON.parse(localStorage.getItem("timer"));
    
    setTimerValue(timerValue);
  }, [timercount, isConfirmed]);

  const timecount = localStorage.getItem("timer")

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

 

  /*** START: Voice call New integration ***/
  useEffect(() => {
    const url = `${CALLING_API_URL + "voice/token"}`;
    fetch(url)
      .then((response) => response.json())
      .then(({ token }) => {
        setToken(token);
      });
    dispatch(handleDevice({}));
  }, []);

  const unloadCallback = (event) => {

    device._activeCall.disconnect();

  };

  useEffect(() => {
    if (device) {
      dispatch(handleDevice(device));
      dispatch(handleVoiceCall(individualpatient));
      window.addEventListener("beforeunload", (event) => unloadCallback(event));
      return () => window.removeEventListener("beforeunload", unloadCallback);
    }
  }, [device]);

  //new one
  const handleCall = async (e) => {
    e.stopPropagation();
    setRowdata(state);
    setState(states.ON_CALL);
    const device = new Device(token);
    device.register();
    await device.connect({
      params: {
        To: individualpatient.phoneNumber,
      },
    });
    setDevice(device);
    const call = device._activeCall;



    call.on("accept", (call) => {
      console.log("Twilio accepted", call.status());
      dispatch(deviceStatus(VoiceCallStatus.CONNECTED));
    });

    call.on("ignore", (call) => {
      console.log("Twilio ignore", call.status());
    });

    call.on("cancel", (call) => {
      console.log("Twilio canceled.", call.status());
    });

    call.on("reject", (call) => {
      console.log("Twilio rejected.", call.status());
    });

    call.on("reconnecting", (twilioError) => {
      console.log("Twilio Connectivity error: ", twilioError);
    });

    call.on("reconnected", (call) => {
      console.log("Twilio regained connectivity.", call.status());
    });

    call.on("disconnect", (call) => {
      console.log("Twilio disconnected.", call.status());
      setTimeout(() => {
        dispatch(deviceStatus(VoiceCallStatus.DISCONNECTED));
      }, 500);
    });
  };
  /*** END: Voice call New integration ***/



  const screenState = useSelector((state) => state.LoginSlice.login.data);


  const Notesdata = useSelector(
    (state) => state.GetDoctorNotesSlice?.getnote?.data?.doctorNotes
  );

  const createddate = useSelector(
    (state) =>
      state.PatientGetOtherDetailsListSlice?.getotherdetail?.data?.dateList
  );


  const diagnosisList = useSelector(
    (state) =>
      state.PatientGetOtherDetailsListSlice?.getotherdetail?.data?.diagnosisList
  );
  const medicationdetailList = useSelector(
    (state) =>
      state.PatientGetOtherDetailsListSlice?.getotherdetail?.data
        ?.medicationList
  );

  
  const updateMedlist = useSelector (

    (state) =>
      state.PatientUpdateOtherDetailsSlice?.updatedetails?.data?.medicationList
  )

  const patientDetail = useSelector(
    (state) =>
      state.PatientGetOtherDetailsListSlice?.getotherdetail?.data?.patientDetail
  );



  const updateOther = {
    userId: state?.userId
      ? state?.userId
      : localStorage.getItem("stateUserId")
        ? localStorage.getItem("stateUserId")
        : screenState.id,
    medication: medicationList,
    diagnosis: icdList,
    isDescription: true,
    symptomsDescription: symt,
    planeOfCareDescription: plan,
    interventionDescription: inter,
    outcomeDescription: out,
    goalsDescription: goal,
    addttionalNotes: addnote,
  };



  const userType = localStorage.getItem("careproviderId");



  const onSelectchange = React.useCallback(
    (test) => {
      setGraphFilter(test);
      toggleShow(true);
    },
    [dateCreate]
  );

  const onSelectListChange = (e) => {
    setSelectList(e.value);
  };

  const onGlucoseChartFilterChange = (data) => {
    setGlucoseChartFilter(data);
  };
  const onBPChartFilterChange = (data) => {
    setbpChartFilter(data);
  };

  const onWeightChartFilterChange = (data) => {
    setweightChartFilter(data);
  };
  const onPulseChartFilterChange = (data) => {
    setPulseChartFilter(data);
  };


  const addMedicationList = () => {
    if (!name || !dose || !fre || !des) {
      alert("Please enter Medication list details");
      return;
    }
    const data = {
      rxnName: name,
      dose: dose,
      frequency: fre,
      description: des,
    };
    setMedictionList(medicationList.concat(data));
    setDose("");
    setFre("");
    setDes("");
    setName("");
    setShowprompt(true);
    setIsUpdating(false);
  };

  const updateMedicationList = () =>{
    const param = {
      id: medId ? medId : 0,
      userId: state?.userId
        ? state?.userId
        : localStorage.getItem("stateUserId")
          ? localStorage.getItem("stateUserId")
          : screenState.id,
      rxnName: name,
      dose: dose,
      frequency: fre,
      description: des
    
    };

    dispatch(updatemedication(param))
      .then((isSuccess) => {
        if (isSuccess.payload.status == 1) {
          toast.success(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          setMedictionList(isSuccess.payload.data.medicationList);
          setMedUpdated(!updateMedlist)
          setIsUpdating(false);
        } else {
          toast.error(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          setTimeout(() => { }, 1000);
        }
      })
      .catch((error) => {
        alert(error);
      });

      setDose("");
      setFre("");
      setDes("");
      setName("");

  }

  const handleChange = (e) => {
    setNote(e.target.value);
  };

  const handleGetNewReadings = (e) => {
    e.preventDefault();
    const readingParams = {
      patientId: state?.userId
        ? state?.userId
        : localStorage.getItem("stateUserId")
          ? localStorage.getItem("stateUserId")
          : screenState.id,
      bloodPressureDevice: individualpatient?.bloodPressure
        ? individualpatient?.bloodPressure
        : " ",
      bloodSugarDevice: individualpatient?.bloodSugar
        ? individualpatient?.bloodSugar
        : " ",
    };
    dispatch(getNewReadings(readingParams));
  };

  const handleClick = (e, data) => {
    localStorage.setItem("createddate", e.target.innerText);
    setShowDetails(true);
    const param = {
      userId: state?.userId
        ? state?.userId
        : localStorage.getItem("stateUserId")
          ? localStorage.getItem("stateUserId")
          : screenState.id,
      createdDate: data,
    };
    dispatch(getotherdetails(param))
      .then((isSuccess) => {
        if (isSuccess.payload.status == 1) {
          setPatientOtherDetail(isSuccess.payload.data.patientDetail);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addIcdList = () => {
    if (!icd || !icddesc ) {
      alert("Please enter Diagnosis list details");
      return;
    }
    const datas = {
      icdCode: icd,
      description: icddesc,
    };
    seticdList(icdList.concat(datas));
    setIcd("");
    seticddesc("");
    setIsUpdatingdiagn(false)
  };

  const updateDiagnosisList = () => {
    const param = {
      id: diagnId ? diagnId : 0,
      userId: state?.userId
        ? state?.userId
        : localStorage.getItem("stateUserId")
          ? localStorage.getItem("stateUserId")
          : screenState.id,
          icdCode: icd,
          description: icddesc
      
    
    };

    dispatch(updatediagnosis(param))
      .then((isSuccess) => {
        if (isSuccess.payload.status == 1) {
          toast.success(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          seticdList(isSuccess.payload.data.diagnosisList);
          setDiagnUpdated(!diagnosisList)
          setIsUpdatingdiagn(false);
        } else {
          toast.error(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          setTimeout(() => { }, 1000);
        }
      })
      .catch((error) => {
        alert(error);
      });

      setIcd("");
      seticddesc("");
  }

  const handleNotes = () => {
    const param = {
      id: noteId ? noteId : 0,
      userId: state?.userId
        ? state?.userId
        : localStorage.getItem("stateUserId")
          ? localStorage.getItem("stateUserId")
          : screenState.id,
      notes: note,
    };

    dispatch(doctornotes(param))
      .then((isSuccess) => {
        if (isSuccess.payload.status == 1) {
          toast.success(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          setdocNoteList(isSuccess.payload.data.doctorNotes);
          setUpdated(!updated);
        } else {
          toast.error(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          setTimeout(() => { }, 1000);
        }
      })
      .catch((error) => {
        alert(error);
      });

    setNote("");

  };



  const handleRow = (data) => {
    setNote(data.notes);
    setNoteId(data.id);
  };

  const handleMedRow = (rowData) =>{

    if (rowData.id) {
      setName(rowData.rxnName);
      setDose(rowData.dose);
      setFre(rowData.frequency);
      setDes(rowData.description);
      setMedId(rowData.id);
      setIsUpdating(true);
    }

  }

  const handleDeleteRow = (rowData, medicationList) => {

// Ensure medicationList is not undefined or null before using slice
if (medicationList && Array.isArray(medicationList)) {
  
  if (rowData >= 0 && rowData < medicationList.length) {
    const updatedList = [
      ...medicationList.slice(0, rowData),
      ...medicationList.slice(rowData + 1)
    ];
    setMedictionList(updatedList);
  } else {
    console.error("Invalid index:", rowData);
  }
} else {
  console.error("medicationList is not an array");
}
  };

  const handleDiagnRow = (rowData) =>{

    if (rowData.id) {
    setIcd(rowData.icdCode);
    seticddesc(rowData.description);
    setDiagnId(rowData.id);
    setIsUpdatingdiagn(true)
    }

  }

  const handleDeleteDiagRow = (rowData, icdList) => {

// Ensure medicationList is not undefined or null before using slice
if (icdList && Array.isArray(icdList)) {
  
  if (rowData >= 0 && rowData < icdList.length) {
    const updatedListdig = [
      ...icdList.slice(0, rowData),
      ...icdList.slice(rowData + 1)
    ];
    seticdList(updatedListdig);
  } else {
    console.error("Invalid index:", rowData);
  }
} else {
  console.error("medicationList is not an array");
}
  };

  const openNoteDeleteModal = (rowData) => {
    console.log("openNoteDeleteModal", rowData)

    setDeleteId(rowData.id);
    setShow(true);
  };

  const openMedDeleteModal = (rowData) => {
    console.log("openMedDeleteModal", rowData )

    setDeleteMedId(rowData.id);
    setShowmed(true);
    console.log("show2")
  };

  const openDiagDeleteModal = (rowData) => {
    console.log("openDiagDeleteModal", rowData )

    setDeleteDiagId(rowData.id);
    setShowdiag(true);
    console.log("show2")
  };

  const handleDeletenotes = (data) => {
    const params = {
      id: deleteId,
    };
    console.log("noteparams", params)

    dispatch(deletedoctornotes(params))
      .then((isSuccess) => {
        console.log("isSuccess", isSuccess)

        if (isSuccess.payload.status == 1) {
          toast.success(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          const params = {
            pageNo: 1,
            pageSize: 5,
            search: "",
          };
          dispatch(deletedoctornotes(params));
          setTimeout(() => { }, 500);
          setShow(false);
        } else {
          toast.error(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setTimeout(() => { }, 500);
        }
      })
      .catch((error) => {
        alert(error);
      });
    setUpdated(!updated);
  };

  const handledeleteMed = (data)=>{
    console.log(localStorage.getItem("stateUserId"));
    
    const params = {
      medicationId: deleteMedId,
      userId: state?.userId
      ? state?.userId
      : localStorage.getItem("stateUserId")
        ? localStorage.getItem("stateUserId")
        : screenState.id,
    };
        console.log("medparams", params )

    dispatch(deletemedication(params))
    .then((isSuccess) => {
      if (isSuccess.payload.status == 1) {

        toast.success(isSuccess.payload.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });

        setMedictionList(isSuccess.payload.data.medicationList);
        const params = {
          pageNo: 1,
          pageSize: 5,
          search: "",
        };
        dispatch(deletemedication(params));
        setTimeout(() => { }, 500);
        setShowmed(false);
      } else {
        toast.error(isSuccess.payload.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setTimeout(() => { }, 500);
      }
    })
    .catch((error) => {
      alert(error);
    });
  }

  const handledeletediag = (data)=>{
    console.log(localStorage.getItem("stateUserId"));
    
    const params = {
      diagnosisId: deleteDiagId,
      userId: state?.userId
      ? state?.userId
      : localStorage.getItem("stateUserId")
        ? localStorage.getItem("stateUserId")
        : screenState.id,
    };
        console.log("diagparams", params )

    dispatch(deletediagnosis(params))
    .then((isSuccess) => {
      if (isSuccess.payload.status == 1) {

        toast.success(isSuccess.payload.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });

        seticdList(isSuccess.payload.data.diagnosisList);
        const params = {
          pageNo: 1,
          pageSize: 5,
          search: "",
        };
        dispatch(deletediagnosis(params));
        setTimeout(() => { }, 500);
        setShowdiag(false);
      } else {
        toast.error(isSuccess.payload.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setTimeout(() => { }, 500);
      }
    })
    .catch((error) => {
      alert(error);
    });
    // const [medupdated, setMedUpdated] = useState(false);
    // setMedUpdated(!medupdated);

    // setMedUpdated(!updateMedlist)


  }

  const actionBodyTemplate1 = (rowData) => {
    return (
      <>
        <div style={{ marginLeft: "1.5em" }}>
          {rowData?.gender == "Male" ? (
            <BsGenderMale size={25} />
          ) : rowData?.gender == "Female" ? (
            <BsGenderFemale size={25} />
          ) : (
            ""
          )}
        </div>
      </>
    );
  };

  const location = useLocation();

  const maximumDate = new Date();
  const minimumDate = new Date();

  minimumDate.setDate(maximumDate.getDate() - 250);

  useEffect(() => {
    const paramnote = {};
    const paramccm = {};
    if (screenState?.userType == 3) {
      paramnote["patientId"] = screenState?.id;
      paramnote["sortOrder"] = "desc";
      paramccm["userId"] = state?.userId
        ? state?.userId
        : localStorage.getItem("stateUserId")
          ? localStorage.getItem("stateUserId")
          : screenState.id;
    } else {
      paramnote["patientId"] = state?.userId
        ? state?.userId
        : localStorage.getItem("stateUserId")
          ? localStorage.getItem("stateUserId")
          : screenState.id;
      paramnote["sortOrder"] = "desc";
      paramccm["userId"] = state?.userId
        ? state?.userId
        : localStorage.getItem("stateUserId")
          ? localStorage.getItem("stateUserId")
          : screenState.id;
    }

    screenState?.userType != 3 && dispatch(getotherdetailslist(paramccm));
    dispatch(getotherdetailslist(paramccm));
    dispatch(getdoctornotes(paramnote));
    const gettimer = {
      userId: state?.userId
        ? state?.userId
        : localStorage.getItem("stateUserId")
          ? localStorage.getItem("stateUserId")
          : screenState.id,
    };
    dispatch(gettimerdetail(gettimer));
    dispatch(getccmtimerdetail(gettimer));
    dispatch(getprofiledetail()).then((isSuccess) => {

      if (isSuccess.payload.status == 0) {
        setProfileDetail(isSuccess.payload.data.avatarURL);
      }
    });
    setMedictionList(medicationdetailList);
    setdocNoteList(Notesdata);
    seticdList(diagnosisList);
    setDateCreate(createddate);
    setPatientDetails(patientDetail);
  }, [patientDetails, graphFilter, updated, medupdated, diagnupdated]);

  useEffect(() => {
    const gettimer = {
      userId: state?.userId
        ? state?.userId
        : localStorage.getItem("stateUserId")
          ? localStorage.getItem("stateUserId")
          : screenState.id,
    };
    //dispatch(gettimerdetail(gettimer));
  }, []);

  const [timerIcon, setTimerIcon] = useState("")

  useEffect(() => {

    if (JSON.parse(localStorage.getItem("userType")) !== 3) {
      window.addEventListener("beforeunload", handleBeforeUnload);

      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
        const confirmed = window.confirm("Do you want to Exit this Page ? ");
        if (!confirmed) {

          navigate("/CareProvider/MyHealth");

        }

        window.location.reload();
      };
    }

  }, []);

  useEffect(() => {
    if (
      individualpatient?.bloodPressure === "OmronBloodPressure" ||
      individualpatient?.bloodPressure == "iGlucoseBloodPressure" ||
      individualpatient?.bloodPressure == "BodyTraceBloodPressure"||
      individualpatient?.bloodPressure == "ContinuaBloodPressure"
    ) {
      const params = {
        dataItemName: bloodPressureName,
        dataItemFrom: filterValue == "Last 30 days" ? "Monthly" : filterValue,
        dataItemVersion: "1.0",
        patientId: state?.userId ? state?.userId : individualpatient?.userId,
      };

      dispatch(getFilteredData(params))
        .then((isSuccess) => {

          if (isSuccess.payload.status == 1) {


            const dataItemsWithCentralTime = isSuccess.payload.data.dataItemsSavedOutputModelList.map((item) => {
              // Always treat the `createdOn` field as UTC
              const utcDate = new Date(item.createdOn + 'Z'); // Appending 'Z' ensures it's treated as UTC
              const centralDate = new Date(utcDate.toLocaleString("en-US", { timeZone: "America/Chicago" }));
          
              return {
                  ...item, // Keep the existing fields
                  createdOn: centralDate.toISOString(), // Replace `createdOn` with Central Time
              };
          });

            if (individualpatient?.bloodPressure === "OmronBloodPressure") {
              setBloodPressureOmronData(
                isSuccess.payload.data.dataItemsSavedOutputModelList
              );
            } else if (
              individualpatient?.bloodPressure == "iGlucoseBloodPressure"
            ) {
              setBloodPressureIglucoseData(() =>{
                let dataWithDuplicate = [...isSuccess.payload.data.dataItemsSavedOutputModelList];
                  let mapDuplicateIndex = {};
                  dataWithDuplicate?.forEach((item,index) => {
                    const createdOn = item.createdOn;
                    if(mapDuplicateIndex[createdOn] === undefined){
                      mapDuplicateIndex[createdOn] = index;
                    }

                  })
                  let readingWithoutDuplicate = dataWithDuplicate?.filter((item,index) =>  index === mapDuplicateIndex[item.createdOn])

                 
              // console.log("::::::1",isSuccess.payload.data.dataItemsSavedOutputModelList,readingWithoutDuplicate);
              return readingWithoutDuplicate?.length > 0 ? readingWithoutDuplicate : [];
              }
                // isSuccess.payload.data.dataItemsSavedOutputModelList
              );
            } else if (
              individualpatient?.bloodPressure == "BodyTraceBloodPressure"
            ) {
              setBodyTraceBpData( isSuccess.payload.data.dataItemsSavedOutputModelList);
            }else if(
              individualpatient?.bloodPressure == "ContinuaBloodPressure"
            ){
              setBloodPressureContinuaData(dataItemsWithCentralTime);
            }
            if (
              JSON.parse(
                isSuccess.payload.data.dataItemsSavedOutputModelList[
                  isSuccess.payload.data.dataItemsSavedOutputModelList.length -
                  1
                ].dataItemData
              ).systolic > 0
            ) {
              setLatestSystolicValue(
                JSON.parse(
                  isSuccess.payload.data.dataItemsSavedOutputModelList[
                    isSuccess.payload.data.dataItemsSavedOutputModelList
                      .length - 1
                  ].dataItemData
                ).systolic
              );
            }
            if (
              JSON.parse(
                isSuccess.payload.data.dataItemsSavedOutputModelList[
                  isSuccess.payload.data.dataItemsSavedOutputModelList.length -
                  1
                ].dataItemData
              ).diastolic > 0
            ) {
              setLatestDiastolicValue(
                JSON.parse(
                  isSuccess.payload.data.dataItemsSavedOutputModelList[
                    isSuccess.payload.data.dataItemsSavedOutputModelList
                      .length - 1
                  ].dataItemData
                ).diastolic
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [filterValue, graphFilter, dataItemName, bloodPressureName]);

  useEffect(() => {

    if (
      individualpatient?.bloodSugar == "BionimeBloodSugar" ||
      individualpatient?.bloodSugar == "iGlucoseBloodSugar" ||
      individualpatient?.bloodSugar == "ContinuaBloodGlucose"
    ) {
      const params = {
        dataItemName: bloodSugarName,
        dataItemFrom: filterValue == "Last 30 days" ? "Monthly" : filterValue,
        dataItemVersion: "1.0",
        patientId: state?.userId ? state?.userId : individualpatient?.userId,
      };

      dispatch(getFilteredData(params))
        .then((isSuccess) => {
          // console.log(isSuccess.payload);
          if (isSuccess.payload.status == 1) {
            if (individualpatient?.bloodSugar == "BionimeBloodSugar") {
              setBloodSugarBionimeData(
                isSuccess.payload.data.dataItemsSavedOutputModelList
              );
            } else if (individualpatient?.bloodSugar == "iGlucoseBloodSugar") {
              setIglucoseBloodSugarData(
                isSuccess.payload.data.dataItemsSavedOutputModelList
             )
            }else if (individualpatient?.bloodSugar == "ContinuaBloodGlucose") {
              setIglucoseBloodSugarData(
                isSuccess.payload.data.dataItemsSavedOutputModelList
              );
            }
          }
          
          //   if (
          //     JSON.parse(
          //       isSuccess.payload.data.dataItemsSavedOutputModelList[
          //         isSuccess.payload.data.dataItemsSavedOutputModelList.length -
          //         1
          //       ].dataItemData
          //     ).bloodglucose > 0
          //   ) {
          //     setLatestGlucoseValue(
          //       JSON.parse(
          //         isSuccess.payload.data.dataItemsSavedOutputModelList[
          //           isSuccess.payload.data.dataItemsSavedOutputModelList
          //             .length - 1
          //         ].dataItemData
          //       ).bloodglucose
          //     );
          //   }
          //   if (
          //     JSON.parse(
          //       isSuccess.payload.data.dataItemsSavedOutputModelList[
          //         isSuccess.payload.data.dataItemsSavedOutputModelList.length -
          //         1
          //       ].dataItemData
          //     ).PulseBpm > 0
          //   ) {
          //     setLatestPulseOxValue(
          //       JSON.parse(
          //         isSuccess.payload.data.dataItemsSavedOutputModelList[
          //           isSuccess.payload.data.dataItemsSavedOutputModelList
          //             .length - 1
          //         ].dataItemData
          //       ).PulseBpm
          //     );
          //   }
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [filterValue, graphFilter, dataItemName, bloodPressureName]);

  useEffect(() => {
    if (individualpatient?.pulseOX == "iGlucosePulseOX") {
      const params = {
        dataItemName: pulseOxName,
        dataItemFrom: filterValue == "Last 30 days" ? "Monthly" : filterValue,
        dataItemVersion: "1.0",
        patientId: state?.userId ? state?.userId : individualpatient?.userId,
      };

      dispatch(getFilteredData(params))
        .then((isSuccess) => {
          if (isSuccess.payload.status == 1) {
            if (individualpatient?.pulseOX == "iGlucosePulseOX") {
              setPulseData(
                isSuccess.payload.data.dataItemsSavedOutputModelList
              );
            }

            if (
              JSON.parse(
                isSuccess.payload.data.dataItemsSavedOutputModelList[
                  isSuccess.payload.data.dataItemsSavedOutputModelList.length -
                  1
                ].dataItemData
              ).PulseBpm > 0
            ) {
              setLatestPulseOxValue(
                JSON.parse(
                  isSuccess.payload.data.dataItemsSavedOutputModelList[
                    isSuccess.payload.data.dataItemsSavedOutputModelList
                      .length - 1
                  ].dataItemData
                ).PulseBpm
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [filterValue, graphFilter, dataItemName, bloodPressureName]);

  useEffect(() => {
    if (individualpatient?.weightScale == "BodyTraceWeight") {
      const params = {
        dataItemName: "BodyTraceWeight",
        dataItemFrom: filterValue == "Last 30 days" ? "Monthly" : filterValue,
        dataItemVersion: "1.0",
        patientId: state?.userId ? state?.userId : individualpatient?.userId,
      };

      dispatch(getFilteredData(params))
        .then((isSuccess) => {
          if (isSuccess.payload.status == 1) {
            if (individualpatient?.weightScale == "BodyTraceWeight") {
              setBodyWeightData(
                isSuccess.payload.data.dataItemsSavedOutputModelList
              );
            }

            if (
              JSON.parse(
                isSuccess.payload.data.dataItemsSavedOutputModelList[
                  isSuccess.payload.data.dataItemsSavedOutputModelList.length -
                  1
                ].dataItemData
              ).weight > 0
            ) {
              setLatestBodyweightValue(
                JSON.parse(
                  isSuccess.payload.data.dataItemsSavedOutputModelList[
                    isSuccess.payload.data.dataItemsSavedOutputModelList
                      .length - 1
                  ].dataItemData
                ).weight
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [filterValue, graphFilter, dataItemName, bloodPressureName]);

  const handleBeforeUnload = (e) => {
    e.preventDefault();
  };


  const handlePatientDetailsSubmit = () => {
    // if (promptAlert === true) {
    //   const data = [];
    //   const datas = [];

      // setMedictionList(medicationList);
      // seticdList(icdList);
      dispatch(updateotherdetails(updateOther))
        .then(async (isSuccess) => {
          if (isSuccess.payload !== undefined) {

            toast.success(isSuccess.payload.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            // console.log('isSuccess.payload.data.dateList',isSuccess.payload.data.medicationList);
            setMedictionList(isSuccess.payload.data.medicationList)
            seticdList(isSuccess.payload.data.diagnosisList);
            setDateCreate(isSuccess.payload.data.dateList);
            setSymt("")
            setPlan("")
            setGoal("")
            setOut("")
            setInter("")
            setAddnote("")
            setTimeout(() => { }, 1000);
          } else {
            toast.error("Could not be Updated", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            setTimeout(() => { }, 1000);
          }
        })
        .catch(({ error }) => {
          console.log(error);
        });
    // } else {
    //   alert();
    // }
  };

  const dateTemplate = (Notesdata) => {
    return moment(Notesdata["createdDate"]).format("MM-DD-YYYY");
  };

  const actionBodyTemplate = (rowData) => {
    return (

      <React.Fragment>
        {userType == 2 ? (
          <>
            <Tooltip
              title={<p style={{ fontSize: "10px" }}>Edit Note</p>}
              placement="right-start"
              arrow
            >
              <img
                disabled
                style={{ opacity: 0.5, pointerEvents: "none" }}
                className="edidicon"
                onClick={() => handleRow(rowData)}
                src={editcard}
                alt="edit"
              />
            </Tooltip>
            <Tooltip
              title={<p style={{ fontSize: "10px" }}>Delete Note</p>}
              placement="right-start"
              arrow
            >
              <img
                disabled
                style={{
                  opacity: 0.5,
                  cursor: "pointer",
                  pointerEvents: "none",
                }}
                onClick={() => openNoteDeleteModal(rowData)}
                src={remove}
                alt="remove"
              />
            </Tooltip>
          </>
        ) : (
          <>
            <img
              className="edidicon"
              onClick={() => handleRow(rowData)}
              src={editcard}
              alt="edit"
            />
            <img
              style={{ cursor: "pointer" }}
              onClick={() => openNoteDeleteModal(rowData)}
              src={remove}
              alt="remove"
            />
          </>
        )}
      </React.Fragment>
    );
  };
  const actionBodyMedTemplate = (rowData, { rowIndex }) => {
    return (
      <React.Fragment>
      {userType === 2 ?(
        <>
              <img
                disabled
                style={{ opacity: 0.5, pointerEvents: "none" }}
                className="edidicon"
                onClick={() => handleMedRow(rowData)}
                src={editcard}
                alt="edit"
              />
               <img
                  className="space-icon"
                  src={del_icon}
                  //onClick={handledeleteMed}
                  onClick={() => openMedDeleteModal(rowData, { rowIndex })}
                   alt="delete"
                />           
          </>
          ): (rowData.id ? (
            <>
              <img
                className="edidicon"
                onClick={() => handleMedRow(rowData)}
                src={editcard}
                alt="edit"
              />
                <img
                  className="space-icon"
                  src={del_icon}
                   //onClick={handledeleteMed}
                   onClick={() =>openMedDeleteModal(rowData)}
                   alt="delete"
                />

              </>
              ) : (
                <img
              style={{ cursor: "pointer" }}
              onClick={() => handleDeleteRow(rowIndex, medicationList)}
              src={remove}
              alt="remove"
            />
              )
        )}
    </React.Fragment>
      
    );
  };

  const actionBodyDiagnTemplate = (rowData, { rowIndex }) => {
    return (

      <React.Fragment>
        {userType == 2 ? (
          <>
              <img
                disabled
                style={{ opacity: 0.5, pointerEvents: "none" }}
                className="edidicon"
                onClick={() => handleDiagnRow(rowData)}
                src={editcard}
                alt="edit"
              />
               <img
                  className="space-icon"
                  src={del_icon}
                  onClick={() =>openDiagDeleteModal(rowData)}

                   alt="delete"
                />
          
     
          </>
        ) : (rowData.id ? (
          <>
          <img 
          className="edidicon"
          onClick={() => handleDiagnRow(rowData)}
          src={editcard}
          alt="edit"
        />
            <img
                  className="space-icon"
                  src={del_icon}
                  onClick={() =>openDiagDeleteModal(rowData)}
                   alt="delete"
                />
        </>
        ):(
          <>
          <img
              style={{ cursor: "pointer" }}
              onClick={() => handleDeleteDiagRow(rowIndex, icdList)}
              src={remove}
              alt="remove"
            />
           
          </>
        )
      )}
      </React.Fragment>
    );
  };

  const removePatient = () => {
    const params = {
      id: individualpatient.userId,
    };
    dispatch(deletepatient(params))
      .then((isSuccess) => {
        if (isSuccess.payload.status == 1) {
          toast.success(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          setTimeout(() => {
            navigate("/careprovider/managepatients");
          }, 1000);
        } else {
          toast.error(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          setTimeout(() => { }, 1000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const navigatePatientInfo = (e) => {

    if (screenState?.userType == 1) {
      navigate(EDIT_PATIENTS_ROUTE, { state: individualpatient });
    } else if (screenState?.userType == 2) {
      navigate(SEDIT_PATIENTS_ROUTE, { state: individualpatient });
    } else {
      navigate(PEDIT_PATIENTS_ROUTE, { state: individualpatient });
    }
  };

  const openDeleteModal = () => {
    setShowModal(true);
  };

  const exportToPdf = () => {
    navigate("/reports");
  };


  function convertUTCDateToLocalDate(date) {
    var dateLocal = new Date(date);
    var newDate = new Date(
      dateLocal.getTime() - dateLocal.getTimezoneOffset() * 60 * 1000
    );
    return newDate;
  }

  const handleFormSubmit = () => { };

  const handleBloodSugarBionomineFormSubmit = (e) => {
    e.preventDefault();
    if (
      sugarStartDate != "" &&
      sugarEndDate != "" &&
      sugarEndDate > sugarStartDate
    ) {
      const bpParams = {
        dataItemName: "BionimeBloodSugar",
        dataItemVersion: "1.0",
        patientId: state?.userId ? state?.userId : individualpatient?.userId,
        startDate: convertUTCDateToLocalDate(sugarStartDate),
        endDate: convertUTCDateToLocalDate(sugarEndDate),
      };
      dispatch(getIGlucose(bpParams))
        .then((isSuccess) => {
          if (isSuccess.payload.status == 1) {
            console.log(
              "isSuccess==",
              isSuccess.payload.data.dataItemsSavedOutputModelList
            );
            setBloodSugarBionimeData(
              isSuccess.payload.data.dataItemsSavedOutputModelList
            );

            if (
              JSON.parse(
                isSuccess.payload.data.dataItemsSavedOutputModelList[
                  isSuccess.payload.data.dataItemsSavedOutputModelList.length -
                  1
                ].dataItemData
              ).bloodglucose > 0
            ) {
              setLatestGlucoseValue(
                JSON.parse(
                  isSuccess.payload.data.dataItemsSavedOutputModelList[
                    isSuccess.payload.data.dataItemsSavedOutputModelList
                      .length - 1
                  ].dataItemData
                ).bloodglucose
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Please Check startDate and EndDate", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }
  };

  const handleContinuaBloodGlucoseFormSubmit = (e) => {
    e.preventDefault();
    if (
      sugarStartDate != "" &&
      sugarEndDate != "" &&
      sugarEndDate > sugarStartDate
    ) {
      const bpParams = {
        dataItemName: "ContinuaBloodGlucose",
        dataItemVersion: "1.0",
        patientId: state?.userId ? state?.userId : individualpatient?.userId,
        startDate: convertUTCDateToLocalDate(sugarStartDate),
        endDate: convertUTCDateToLocalDate(sugarEndDate),
      };
      dispatch(getIGlucose(bpParams))
        .then((isSuccess) => {
          if (isSuccess.payload.status == 1) {
          
            setIglucoseBloodSugarData(
              isSuccess.payload.data.dataItemsSavedOutputModelList
            );

            if (
              JSON.parse(
                isSuccess.payload.data.dataItemsSavedOutputModelList[
                  isSuccess.payload.data.dataItemsSavedOutputModelList.length -
                  1
                ].dataItemData
              ).bloodglucose > 0
            ) {
              setLatestGlucoseValue(
                JSON.parse(
                  isSuccess.payload.data.dataItemsSavedOutputModelList[
                    isSuccess.payload.data.dataItemsSavedOutputModelList
                      .length - 1
                  ].dataItemData
                ).bloodglucose
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Please Check startDate and EndDate", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }
  };





  const handleIglucoseBloodSugarFormSubmit = (e) => {
    e.preventDefault();
    if (
      sugarStartDate != "" &&
      sugarEndDate != "" &&
      sugarEndDate > sugarStartDate
    ) {
      const bpParams = {
        dataItemName: "iGlucoseBloodSugar",
        dataItemVersion: "1.0",
        patientId: state?.userId ? state?.userId : individualpatient?.userId,
        startDate: convertUTCDateToLocalDate(sugarStartDate),
        endDate: convertUTCDateToLocalDate(sugarEndDate),
      };
      dispatch(getIGlucose(bpParams))
        .then((isSuccess) => {
          if (isSuccess.payload.status == 1) {
          
            setIglucoseBloodSugarData(
              isSuccess.payload.data.dataItemsSavedOutputModelList
            );

            if (
              JSON.parse(
                isSuccess.payload.data.dataItemsSavedOutputModelList[
                  isSuccess.payload.data.dataItemsSavedOutputModelList.length -
                  1
                ].dataItemData
              ).bloodglucose > 0
            ) {
              setLatestGlucoseValue(
                JSON.parse(
                  isSuccess.payload.data.dataItemsSavedOutputModelList[
                    isSuccess.payload.data.dataItemsSavedOutputModelList
                      .length - 1
                  ].dataItemData
                ).bloodglucose
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Please Check startDate and EndDate", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }
  };

  const handleBloodPressureOmronFormSubmit = (e) => {
    e.preventDefault();
    if (
      IGlucoseStartDate != null &&
      IGlucoseEndDate != null &&
      IGlucoseEndDate > IGlucoseStartDate
    ) {
      const bpParams = {
        dataItemName: "OmronBloodPressure",
        dataItemVersion: "1.0",
        patientId: state?.userId ? state?.userId : individualpatient?.userId,
        startDate: convertUTCDateToLocalDate(IGlucoseStartDate),
        endDate: convertUTCDateToLocalDate(IGlucoseEndDate),
      };
      dispatch(getIGlucose(bpParams))
        .then((isSuccess) => {
          if (isSuccess.payload.status == 1) {
          
            setBloodPressureOmronData(
              isSuccess.payload.data.dataItemsSavedOutputModelList
            );

            if (
              JSON.parse(
                isSuccess.payload.data.dataItemsSavedOutputModelList[
                  isSuccess.payload.data.dataItemsSavedOutputModelList.length -
                  1
                ].dataItemData
              ).systolic > 0
            ) {
              setLatestSystolicValue(
                JSON.parse(
                  isSuccess.payload.data.dataItemsSavedOutputModelList[
                    isSuccess.payload.data.dataItemsSavedOutputModelList
                      .length - 1
                  ].dataItemData
                ).systolic
              );
            }
            if (
              JSON.parse(
                isSuccess.payload.data.dataItemsSavedOutputModelList[
                  isSuccess.payload.data.dataItemsSavedOutputModelList.length -
                  1
                ].dataItemData
              ).diastolic > 0
            ) {
              setLatestDiastolicValue(
                JSON.parse(
                  isSuccess.payload.data.dataItemsSavedOutputModelList[
                    isSuccess.payload.data.dataItemsSavedOutputModelList
                      .length - 1
                  ].dataItemData
                ).diastolic
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Please Check startDate and EndDate", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }
  };

  const handleBloodPressureIglucoseFormSubmit = (e) => {
      
    e.preventDefault();
    
    if (
      IGlucoseStartDate != null &&
      IGlucoseEndDate != null &&
      IGlucoseEndDate > IGlucoseStartDate
    ) {
      const bpParams = {
        dataItemName: "iGlucoseBloodPressure",
        dataItemVersion: "1.0",
        patientId: state?.userId ? state?.userId : individualpatient?.userId,
        startDate: convertUTCDateToLocalDate(IGlucoseStartDate),
        endDate: convertUTCDateToLocalDate(IGlucoseEndDate),
      };
      dispatch(getIGlucose(bpParams))
        .then((isSuccess) => {
          if (isSuccess.payload.status == 1) {
        
            setBloodPressureIglucoseData(() => {
                  let dataWithDuplicate = [...isSuccess.payload.data.dataItemsSavedOutputModelList];
                  let mapDuplicateIndex = {};
                  dataWithDuplicate?.forEach((item,index) => {
                    const createdOn = item.createdOn;
                    if(mapDuplicateIndex[createdOn] === undefined){
                      mapDuplicateIndex[createdOn] = index;
                    }

                  })
                  let readingWithoutDuplicate = dataWithDuplicate?.filter((item,index) =>  index === mapDuplicateIndex[item.createdOn])

                 
              // console.log("::::::1",isSuccess.payload.data.dataItemsSavedOutputModelList,readingWithoutDuplicate);
              return readingWithoutDuplicate?.length > 0 ? readingWithoutDuplicate : [];
            }

              
            );

            if (
              JSON.parse(
                isSuccess.payload.data.dataItemsSavedOutputModelList[
                  isSuccess.payload.data.dataItemsSavedOutputModelList.length -
                  1
                ].dataItemData
              ).systolic > 0
            ) {
              setLatestSystolicValue(
                JSON.parse(
                  isSuccess.payload.data.dataItemsSavedOutputModelList[
                    isSuccess.payload.data.dataItemsSavedOutputModelList
                      .length - 1
                  ].dataItemData
                ).systolic
              );
            }
            if (
              JSON.parse(
                isSuccess.payload.data.dataItemsSavedOutputModelList[
                  isSuccess.payload.data.dataItemsSavedOutputModelList.length -
                  1
                ].dataItemData
              ).diastolic > 0
            ) {
              setLatestDiastolicValue(
                JSON.parse(
                  isSuccess.payload.data.dataItemsSavedOutputModelList[
                    isSuccess.payload.data.dataItemsSavedOutputModelList
                      .length - 1
                  ].dataItemData
                ).diastolic
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Please Check startDate and EndDate", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }
  };
  const handleBloodPressureContinuaFormSubmit = (e) => {
      
    e.preventDefault();
    
    if (
      IGlucoseStartDate != null &&
      IGlucoseEndDate != null &&
      IGlucoseEndDate > IGlucoseStartDate
    ) {
      const bpParams = {
        dataItemName: "ContinuaBloodPressure",
        dataItemVersion: "1.0",
        patientId: state?.userId ? state?.userId : individualpatient?.userId,
        startDate: convertUTCDateToLocalDate(IGlucoseStartDate),
        endDate: convertUTCDateToLocalDate(IGlucoseEndDate),
      };
      dispatch(getIGlucose(bpParams))
        .then((isSuccess) => {
          // console.log(isSuccess.payload.data);

          if (isSuccess.payload.status == 1) {
            setBloodPressureIglucoseData(() => {
                  let dataWithDuplicate = [...isSuccess.payload.data.dataItemsSavedOutputModelList];
                  let mapDuplicateIndex = {};
                  dataWithDuplicate?.forEach((item,index) => {
                    const createdOn = item.createdOn;
                    if(mapDuplicateIndex[createdOn] === undefined){
                      mapDuplicateIndex[createdOn] = index;
                    }

                  })
                  let readingWithoutDuplicate = dataWithDuplicate?.filter((item,index) =>  index === mapDuplicateIndex[item.createdOn])

                 
              // console.log("::::::1",isSuccess.payload.data.dataItemsSavedOutputModelList,readingWithoutDuplicate);
              return readingWithoutDuplicate?.length > 0 ? readingWithoutDuplicate : [];
            }

              
            );

            if (
              JSON.parse(
                isSuccess.payload.data.dataItemsSavedOutputModelList[
                  isSuccess.payload.data.dataItemsSavedOutputModelList.length -
                  1
                ].dataItemData
              ).systolic > 0
            ) {
              setLatestSystolicValue(
                JSON.parse(
                  isSuccess.payload.data.dataItemsSavedOutputModelList[
                    isSuccess.payload.data.dataItemsSavedOutputModelList
                      .length - 1
                  ].dataItemData
                ).systolic
              );
            }
            if (
              JSON.parse(
                isSuccess.payload.data.dataItemsSavedOutputModelList[
                  isSuccess.payload.data.dataItemsSavedOutputModelList.length -
                  1
                ].dataItemData
              ).diastolic > 0
            ) {
              setLatestDiastolicValue(
                JSON.parse(
                  isSuccess.payload.data.dataItemsSavedOutputModelList[
                    isSuccess.payload.data.dataItemsSavedOutputModelList
                      .length - 1
                  ].dataItemData
                ).diastolic
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Please Check startDate and EndDate", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }
  };
  const handlePulseFormSubmit = (e) => {
    e.preventDefault();
    if (
      pulseStartDate != "" &&
      pulseEndDate != "" &&
      pulseEndDate > pulseStartDate
    ) {
      const bpParams = {
        dataItemName: "iGlucosePulseOX",
        dataItemVersion: "1.0",
        patientId: state?.userId ? state?.userId : individualpatient?.userId,
        startDate: convertUTCDateToLocalDate(pulseStartDate),
        endDate: convertUTCDateToLocalDate(pulseEndDate),
      };
      dispatch(getIGlucose(bpParams))
        .then((isSuccess) => {
          if (isSuccess.payload.status == 1) {
           
            setPulseData(isSuccess.payload.data.dataItemsSavedOutputModelList);

            if (
              JSON.parse(
                isSuccess.payload.data.dataItemsSavedOutputModelList[
                  isSuccess.payload.data.dataItemsSavedOutputModelList.length -
                  1
                ].dataItemData
              ).PulseBpm > 0
            ) {
              setLatestPulseOxValue(
                JSON.parse(
                  isSuccess.payload.data.dataItemsSavedOutputModelList[
                    isSuccess.payload.data.dataItemsSavedOutputModelList
                      .length - 1
                  ].dataItemData
                ).PulseBpm
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Please Check startDate and EndDate", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }
  };

  const handleBodyWeightFormSubmit = (e) => {
    e.preventDefault();
    if (
      bodyWeightStartDate != "" &&
      bodyWeightEndDate != "" &&
      bodyWeightEndDate > bodyWeightStartDate
    ) {
      const bpParams = {
        dataItemName: "BodytraceWeight",
        dataItemVersion: "1.0",
        patientId: state?.userId ? state?.userId : individualpatient?.userId,
        startDate: convertUTCDateToLocalDate(bodyWeightStartDate),
        endDate: convertUTCDateToLocalDate(bodyWeightEndDate),
      };
      dispatch(getIGlucose(bpParams))
        .then((isSuccess) => {
          if (isSuccess.payload.status == 1) {
           
            setBodyWeightData(
              isSuccess.payload.data.dataItemsSavedOutputModelList
            );

            if (
              JSON.parse(
                isSuccess.payload.data.dataItemsSavedOutputModelList[
                  isSuccess.payload.data.dataItemsSavedOutputModelList.length -
                  1
                ].dataItemData
              ).weight > 0
            ) {
              setLatestBodyweightValue(
                JSON.parse(
                  isSuccess.payload.data.dataItemsSavedOutputModelList[
                    isSuccess.payload.data.dataItemsSavedOutputModelList
                      .length - 1
                  ].dataItemData
                ).weight
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Please Check startDate and EndDate", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }
  };

  const handleBodyTraceBpFormSubmit = (e) => {
    e.preventDefault();
    // console.log("asdcsdcvsd");
    if (
      bodyTraceBpStartDate != "" &&
      bodyTraceBpEndDate != "" &&
      bodyTraceBpEndDate > bodyTraceBpStartDate
    ) {
      const bpParams = {
        dataItemName: "BodyTraceBloodPressure",
        dataItemVersion: "1.0",
        patientId: state?.userId ? state?.userId : individualpatient?.userId,
        startDate: convertUTCDateToLocalDate(bodyTraceBpStartDate),
        endDate: convertUTCDateToLocalDate(bodyTraceBpEndDate),
      };
      dispatch(getIGlucose(bpParams))
        .then((isSuccess) => {
          if (isSuccess.payload.status == 1) {
          
            setBodyTraceBpData(() =>{
              // console.log(isSuccess.payload.data.dataItemsSavedOutputModelList,":::::::::::::");
              return isSuccess.payload.data.dataItemsSavedOutputModelList
            });

            if (
              JSON.parse(
                isSuccess.payload.data.dataItemsSavedOutputModelList[
                  isSuccess.payload.data.dataItemsSavedOutputModelList.length -
                  1
                ].dataItemData
              ).systolic > 0
            ) {
              setLatestSystolicValue(
                JSON.parse(
                  isSuccess.payload.data.dataItemsSavedOutputModelList[
                    isSuccess.payload.data.dataItemsSavedOutputModelList
                      .length - 1
                  ].dataItemData
                ).systolic
              );
            }
            if (
              JSON.parse(
                isSuccess.payload.data.dataItemsSavedOutputModelList[
                  isSuccess.payload.data.dataItemsSavedOutputModelList.length -
                  1
                ].dataItemData
              ).diastolic > 0
            ) {
              setLatestDiastolicValue(
                JSON.parse(
                  isSuccess.payload.data.dataItemsSavedOutputModelList[
                    isSuccess.payload.data.dataItemsSavedOutputModelList
                      .length - 1
                  ].dataItemData
                ).diastolic
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Please Check startDate and EndDate", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }
  };



  const maxScrollWidth = useRef(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const carousel = useRef(null);

  const movePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const moveNext = () => {
    if (
      carousel.current !== null &&
      carousel.current.offsetWidth * currentIndex <= maxScrollWidth.current
    ) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const isDisabled = (direction) => {
    if (direction === "prev") {
      return currentIndex <= 0;
    }

    if (direction === "next" && carousel.current !== null) {
      return (
        carousel.current.offsetWidth * currentIndex >= maxScrollWidth.current
      );
    }

    return false;
  };

  const navigateToChat = () => {
    { (userType == 1) ? navigate(CHAT_ROUTE, { state: individualpatient }) : navigate(CARE_CHAT_ROUTE, { state: individualpatient }) };
  };

  const navigateToVideo = (rowData) => {
    const param = {
      userId: individualpatient.userId,
      meetingLink: "",
    };
    dispatch(videoUser(param));
    window.open(Videolink, "_blank");
  };

  useEffect(() => {
    if (carousel !== null && carousel.current !== null) {
      carousel.current.scrollLeft = carousel.current.offsetWidth * currentIndex;
    }
  }, [currentIndex]);

  useEffect(() => {
    maxScrollWidth.current = carousel.current
      ? carousel.current.scrollWidth - carousel.current.offsetWidth
      : 0;
  }, []);

  const goback = () => {
    navigate(DASHBOARD_ROUTE);
  };
  const goback1 = () => {
    navigate(STAFF_MANAGEPATIENT_ROUTE);
  };

  const handleClose2 = () => {
    setShowStatusModal(!showStatusModal)
  };

  const handleClick1 = () => {
    navigate(CHANGEPASSWORD_ROUTE);
  }

  const returnTime = (min, hr, sec) => {
    const minutes = Math.floor((parseInt(min * 60)));
    const hours = Math.floor((parseInt(hr * 60 * 60)));
    var totalSec = minutes + hours + parseInt(sec)
    setCallbackMinutes(totalSec)

  }

  return (
    <>
      {loginCount <= 1 && locationState.state?.fromLogin ? <Modal
        show={showStatusModal}
        onHide={handleClose2}
        animation={false}
      >
        <Modal.Header
          className="popupclose-btn"
          closeButton>
        </Modal.Header>
        <Modal.Body>It’s a perfect time to change your password to stronger ones.</Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <button style={{ color: "white", background: "#009688" }} variant="primary" onClick={handleClick1}>Click Here</button>
        </Modal.Footer>
      </Modal> : ""}


      {individualpatient != "" ? (
        <>
          {/* {clicked == false ? ( */}
          <div>
            <div>
              <Modal
                show={shows}
                onHide={handleClose1}
                animation={false}
                centered
              >
                <Modal.Header
                  className="popupclose-btn"
                  closeButton
                ></Modal.Header>
                <Modal.Body style={{ fontSize: "20px" }}>
                  Do you want to Delete
                </Modal.Body>
                <Modal.Footer className="footer-delete">
                  <Button variant="secondary" onClick={handleClose1}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleDeletenotes}>
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            <div>
              <Modal
                show={showsmed}
                onHide={handleClose3}
                animation={false}
                centered
              >
                <Modal.Header
                  className="popupclose-btn"
                  closeButton
                ></Modal.Header>
                <Modal.Body style={{ fontSize: "20px" }}>
                  Do you want to Delete
                </Modal.Body>
                <Modal.Footer className="footer-delete">
                  <Button variant="secondary" onClick={handleClose3}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={handledeleteMed}>
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            <div>
              <Modal
                show={showsdiag}
                onHide={handleClose4}
                animation={false}
                centered
              >
                <Modal.Header
                  className="popupclose-btn"
                  closeButton
                ></Modal.Header>
                <Modal.Body style={{ fontSize: "20px" }}>
                  Do you want to Delete
                </Modal.Body>
                <Modal.Footer className="footer-delete">
                  <Button variant="secondary" onClick={handleClose4}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={handledeletediag}>
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            <div className="patientodash">
              <div>
                <div className="oliviaicondiv">
                  {/* <div>{(userType == 1) ? <ArrowBackIcon className="arrow1" onClick={goback} /> : ""}</div> */}
                  <div>
                    {screenState?.userType == 1 ? (
                      <ArrowBackIcon className="arrow1" onClick={goback} />
                    ) : screenState?.userType == 2 ? (
                      <ArrowBackIcon className="arrow1" onClick={goback1} />
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    <Modal
                      show={showModal}
                      onHide={handleClose}
                      animation={false}
                    >
                      <Modal.Header
                        className="popupclose-btn"
                        closeButton
                      ></Modal.Header>
                      <Modal.Body>
                        Do you want to Delete {state?.fullName} ?
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                        <Button variant="primary" onClick={removePatient}>
                          Delete
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>

                  <div className="icondiv">

                    {/* {show &&  */}
                    <div className="option-select">
                      {/* <SelectButtonRpmGraphDemo onSelectchange={onSelectchange} /> */}
                      <SelectButtonRpmGraphDemo
                        individualpatient={individualpatient}
                        onSelectchange={onSelectchange}
                      />
                    </div>
                    {/* } */}
                    <div
                      className="space-icon"
                      onClick={(e) => navigatePatientInfo(e)}
                    >
                      <Tooltip
                        title={<p style={{ fontSize: "10px" }}>Edit User</p>}
                        placement="right-start"
                        arrow
                      >
                        <img src={edit_icon} alt="edit" />
                      </Tooltip>
                      {/* <img style={{ cursor: "pointer" }} src={edit_icon} alt="edit" /> */}
                    </div>
                    {screenState?.userType == 1 ? (
                      <div className="d-flex">
                        <Tooltip
                          title={
                            <p style={{ fontSize: "10px" }}>Delete User</p>
                          }
                          placement="right-start"
                          arrow
                        >
                          <img
                            className="space-icon"
                            src={del_icon}
                            onClick={openDeleteModal}
                            alt="delete"
                          />
                        </Tooltip>
                        <Tooltip
                          title={
                            <p style={{ fontSize: "10px" }}>Export To PDF</p>
                          }
                          placement="right-start"
                          arrow
                        >
                          <img
                            className="space-icon"
                            src={exportpdf}
                            onClick={exportToPdf}
                            alt="delete"
                          />
                        </Tooltip>
                        <ToastContainer />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <Box className="overall-patient">
                  <Grid container spacing={2} className="patient-details">
                    {/* <div className="col-12"> */}

                    <Grid
                      item
                      xl={5}
                      lg={5}
                      md={5}
                      sm={12}
                      xs={12}
                      className="detail-leftside"
                    >
                      <Grid container spacing={2}>
                        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                          <div className="avatardiv">

                            <Avatar className="userview-portal">
                              <img

                                src={
                                  fullName == individualpatient?.fullName
                                    ? profileDetail
                                    : individualpatient?.avatarUrl
                                      ? individualpatient?.avatarUrl
                                      : dummyprofile
                                }

                                className="avadar-image"
                              />
                            </Avatar>
                          </div>
                        </Grid>

                        <Grid
                          item
                          xl={8}
                          lg={8}
                          md={8}
                          sm={8}
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Grid container spacing={2} className="m-0">
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              sm={6}
                              xs={12}
                              className="pt-0"
                            >
                              <div className="textstylecolor spacediv">
                                <div className="maintext">
                                  {individualpatient
                                    ? individualpatient?.fullName
                                    : " "}
                                </div>

                              </div>
                            </Grid>
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              sm={6}
                              xs={12}
                              className="pt-0"
                            >
                              <div className="femaleicon spacediv">
                                {/* {" "} */}
                                {/* {individualpatient ? individualpatient.gender : " "} */}
                                {individualpatient.gender == "Male" ? (
                                  <BsGenderMale size={25} />
                                ) : individualpatient.gender == "Female" ? (
                                  <BsGenderFemale size={25} />
                                ) : (
                                  ""
                                )}
                              </div>

                              {/* <div className="textstyle">Email:</div> */}
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} className="m-0">
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              sm={6}
                              xs={12}
                              className="pt-0"
                            >
                              <div className="textstyle spacediv">
                                Date Of Birth:
                              </div>
                            </Grid>
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              sm={6}
                              xs={12}
                              className="pt-0"
                            >
                              <div className="textstyles2 spacediv">
                                {individualpatient
                                  ? moment(individualpatient.dob).format(
                                    "MM-DD-YYYY"
                                  )
                                  : " "}
                              </div>
                            </Grid>

                          </Grid>


                          <Grid container spacing={2} className="m-0">
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              sm={6}
                              xs={12}
                              className="pt-0"
                            >
                              <div className="textstyle spacediv">
                                Phone Number:
                              </div>
                            </Grid>
                            {/* <div className="textstyle">Address:</div> */}
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              sm={6}
                              xs={12}
                              className="pt-0"
                            >
                              <div className="textstyles2 spacediv">
                                {individualpatient
                                  ? individualpatient.phoneNumber
                                  : " "}
                              </div>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} className="m-0">
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              sm={6}
                              xs={12}
                              className="pt-0"
                            >
                              <div className="textstyle">Email:</div>
                            </Grid>


                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              sm={6}
                              xs={12}
                              className="pt-0"
                            >
                              <div className="textstyles2 overflow-ellipsis w-100">
                                {individualpatient
                                  ? individualpatient.emailId
                                  : " "}
                              </div>
                            </Grid>
                          </Grid>
                          {/* </div> */}
                        </Grid>
                      </Grid>
                    </Grid>


                    {individualpatient?.rpm === true &&
                      graphFilter === "RPM" ? (
                      <>
                        {screenState.userType != 3 && (
                          <Grid
                            item
                            xl={7}
                            lg={7}
                            md={7}
                            sm={12}
                            xs={12}
                            className="profile-right"
                          >
                            {/* <div className='newrecord-section'> */}
                            <div
                              className="new-readings"
                              onClick={handleGetNewReadings}
                            >
                              <img src={linkicon}></img>
                              <span>get new readingss</span>
                            </div>
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xl={1}
                                lg={1}
                                md={1}
                                sm={4}
                                xs={12}
                                className="cantact2"
                              >
                                <div>
                                  <ul className="patient-contact">
                                    {/* <li><img class="vedioicon" src="/static/media/call.ce4000365482cd86c238bee5af09fa24.svg" onClick={handleCall}></img></li> */}
                                    <li>
                                      {" "}
                                      <IconButton
                                        style={{ marginLeft: "-0.5em" }}
                                        onClick={handleCall}
                                        disabled={isDisableCallIcon}
                                      >
                                        {isDisableCallIcon == true ? (
                                          <WifiCalling3Icon
                                            color="disabled"
                                            sx={{ fontSize: 27 }}
                                          />
                                        ) : (
                                          <WifiCalling3Icon
                                            color="success"
                                            sx={{ fontSize: 27 }}
                                          />
                                        )}
                                      </IconButton>
                                    </li>
                                    <li>
                                      <img
                                        class="vedioicon"
                                        src="/static/media/chat.372f96af54d1c8a865e3fdcd6b1bde4b.svg"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          navigateToChat();
                                        }}
                                      ></img>
                                    </li>
                                    <li>
                                      <img
                                        class="vedioicon"
                                        src="/static/media/Videofull.9a9e1f313f6e236ef2e590fc5248e5e4.svg"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          navigateToVideo();
                                        }}
                                      ></img>
                                    </li>
                                  </ul>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xl={4}
                                lg={4}
                                md={4}
                                sm={8}
                                xs={12}
                                className="leftside-details"
                              >
                                <div className="reading-section">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>CPT99457</p>
                                    <div className="reading-right">
                                      {callbackMinutes >= 1200 ? (
                                        <img src={done} alt="Image"></img>
                                      ) : (
                                        ""
                                      )}

                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>CPT99454</p>
                                    <div className="reading-right">
                                      {callbackMinutes >= 1200 ? (
                                        <img src={done} alt="Image"></img>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p className="m-0">CPT99458</p>
                                    <div className="reading-right">

                                      {callbackMinutes >=
                                        2400 ? (
                                        <img src={done} alt="Image"></img>
                                      ) : (
                                        ""
                                      )}
                                      {callbackMinutes >=
                                        3600 ? (
                                        <img src={done} alt="Image"></img>
                                      ) : (
                                        ""
                                      )}
                                      {callbackMinutes >=
                                        4800 ? (
                                        <img src={done} alt="Image"></img>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xl={4}
                                lg={4}
                                md={4}
                                sm={6}
                                xs={12}
                                className="timer-section"
                              >
                                {/* Conditionally render Timer component based on loading state */}
                                {loading ? (
                                  <div>Loading...</div> // Optional: Show a spinner or other loading indicator
                                ) : getpatientTimerData?.patientTimerDetail ? (
                                  <Timer
                                    data={getpatientTimerData.patientTimerDetail}
                                    returnTime={returnTime}
                                  />
                                ) : (
                                  <div>No Timer Data Available</div> // Optional: A fallback if no timer data is available
                                )}



                              </Grid>
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={3}
                                sm={6}
                                xs={12}
                                className="detail-timersec"
                              >
                                <div className="monthly-timer">
                                  <span>Bill for Monthly RPM</span>
                                  <Tooltip
                                    title={<p>Number of Monthly Readings:</p>}
                                    placement="bottom"
                                    arrow
                                  >
                                    {/* <div className='timer-logo'> */}
                                    <div className="timer-logo1">
                                      <img src={timericon}></img>
                                    </div>
                                  </Tooltip>
                                </div>
                              </Grid>
                            </Grid>

                          </Grid>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                    {graphFilter === "CCM" &&
                      individualpatient?.ccm === true ? (
                      <>
                        {screenState.userType != 3 && (
                          <Grid
                            item
                            xl={6}
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            className="profile-right ccm-section"
                          >

                            <div className="new-readings">
                              <img src={linkicon}></img>
                              <span>get new readings</span>
                            </div>
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xl={8}
                                lg={8}
                                md={8}
                                sm={12}
                                xs={12}
                                className="timer-section"
                              >

                                {/* Conditionally render Timer component based on loading state */}
                                {loading2 ? (
                                  <div>Loading...</div> // Optional: Show a spinner or other loading indicator
                                ) : getpatientccmTimerData?.ccmTimerDetail ? (
                                  <TimerCCM
                                    data={getpatientccmTimerData.ccmTimerDetail}
                                  />
                                ) : (
                                  <div>No Timer Data Available</div> // Optional: A fallback if no timer data is available
                                )}




                                {/* {getpatientccmTimerData && (
                                  <TimerCCM
                                    data={
                                      getpatientccmTimerData?.ccmTimerDetail
                                    }
                                  />
                                )} */}


                              </Grid>
                            </Grid>

                          </Grid>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Box>
                <div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                        <Card className="bpcard">
                          <div className="cardstyle">
                            <div>
                              <div className="bpcardtext1">BLOOD PRESSURE</div>
                              <div className="bpcardtextstyle">
                                {latestSystolicValue}/{latestDiastolicValue}
                              </div>
                              <div className="bpcardtextstyles">(mm/Hg)</div>

                            </div>

                            <div className="iconstyle">
                              <img
                                style={{ width: "70px" }}
                                src={bpnew}
                                alt="wave"
                              />
                            </div>
                          </div>
                        </Card>
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                        <Card className="bpcard">
                          <div className="cardstyle">
                            <div>
                              <div className="bpcardtext">WEIGHT</div>
                              <div className="bpcardtextstyle1">
                                {(latestBodyWeightValue / 453.6).toFixed(1)}
                              </div>
                              <div className="bpcardtextstyles3">(lbs)</div>
                            </div>

                            <div className="iconstyle">
                              <img
                                style={{ width: "70px" }}
                                src={weightnew}
                                alt="weight"
                              />
                            </div>
                          </div>
                        </Card>
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                        <Card className="bpcard">
                          <div className="cardstyle">
                            <div>
                              <div className="bpcardtext">PULSEOX</div>
                              <div className="bpcardtextstyle1">
                                {latestPulseOxValue}
                              </div>
                              <div className="bpcardtextstyles3">(PRbpm)</div>
                            </div>

                            <div className="iconstyle">
                              <img
                                style={{ width: "70px" }}
                                src={pulsenew}
                                alt="pulse"
                              />
                            </div>
                          </div>
                        </Card>
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                        <Card className="bpcard">
                          <div className="cardstyle">
                            <div>
                              <div className="bpcardtext1"> BLOOD SUGAR</div>
                              <div className="bpcardtextstyle">
                                {latestGlucoseValue}
                              </div>
                              <div className="bpcardtextstyles4">(dg/ml)</div>
                              {/* {state?.bsStatus == 10 ?
                              <div className="bpcardtextstyles4">HIGH (mg/dL)</div> : <div className="bpcardtextstyles">NORMAL (mg/dL)</div>
                            } */}
                            </div>

                            <div className="iconstyle">
                              <img
                                style={{ width: "70px" }}
                                src={glucosebionimenew}
                                alt="bionome"
                              />
                            </div>
                          </div>
                        </Card>
                      </Grid>
                    </Grid>
                  </div>

                  {/* -----------------Blood Sugar---------------- */}
                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.bloodSugarId === 4 ? (
                    <Box>
                      <Grid
                        container
                        spacing={2}
                        mt={5}
                        className="blooddiv timeprogress"
                      >
                        <Grid
                          item
                          xl={4}
                          lg={4}
                          md={4}
                          sm={12}
                          xs={12}
                          className="pulse-text"
                        >
                          <div>
                            <div className="maintext">
                              BLOOD SUGAR - Continua
                            </div>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xl={8}
                          lg={8}
                          md={8}
                          sm={12}
                          xs={12}
                          className="column-text"
                        >
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={12}
                              sm={12}
                              xs={12}
                              className="monthly-callendar"
                            >
                              <div className="icondiv1">
                                <div className="grapy-section">
                                  <SelectButtonDemo
                                    handleFilterChange={(a) =>
                                      setFilterValue(a)
                                    }
                                  />
                                  {individualpatient?.bloodSugarId === 4 ? (
                                    <div className="selected-option">
                                      <SelectButtonSugarGraphDemo
                                        onGlucoseChartFilterChange={
                                          onGlucoseChartFilterChange
                                        }
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </Grid>
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={12}
                              sm={12}
                              xs={12}
                              className="select-datefield"
                            >
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={3}
                                sm={3}
                                xs={3}
                                className="start-date"
                              >
                                <div className="flex-1 date-overlay">
                                  <div
                                    style={{ color: "#009688" }}
                                    className="eventstart-date"
                                  >
                                    Start Date
                                  </div>
                                  <Calendar
                                    value={sugarStartDate}
                                    onChange={(e) => {
                                      setSugarStartDate(e.value);
                                      setDataItemName("ContinuaBloodGlucose");
                                    }}
                                    placeholder="MM/DD/YYYY"
                                    style={{ width: "100%" }}
                                    maxDate={new Date()}
                                    name="dob"
                                  ></Calendar>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={3}
                                sm={3}
                                xs={3}
                                className="enddate"
                              >
                                <div className="flex-1 date-overlay">
                                  <div
                                    style={{ color: "#009688" }}
                                    className="eventstart-date"
                                  >
                                    End Date
                                  </div>
                                  <Calendar
                                    value={sugarEndDate}
                                    onChange={(e) => setSugarEndDate(e.value)}
                                    placeholder="Enter date"
                                    style={{ width: "100%" }}
                                    name="dob"
                                    maxDate={new Date()}
                                  ></Calendar>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={3}
                                sm={3}
                                xs={3}
                                className="submit-btn"
                              >
                                <div className="flex-1">
                                  <div style={{ color: "#009688" }}></div>

                                  <Button
                                    className="w-100"
                                    label="Submit"
                                    type="submit"
                                    onClick={handleContinuaBloodGlucoseFormSubmit}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    ""
                  )}

                  {/* -----------------Scatter Start---------------- */}

                  {individualpatient?.rpm === false && graphFilter === "RPM" &&
                    individualpatient?.bloodSugarId === 4 ? (
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <div className="barchart">
                            {glucoseChartFiler === "Bar" && (
                              <Card className="graphcard">
                                <div>Sorry, it seems there is no data here for this patient.</div>
                              </Card>)}
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    ""
                  )}

                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.bloodSugarId === 4 ? (
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <div className="barchart">
                            {glucoseChartFiler === "Bar" && (
                              <Card className="graphcard">
                                {iglucoseBloodSugarData.length !== 0 ?
                                  <BarChartGluc
                                    barChartBpGlucoseData={iglucoseBloodSugarData}
                                  /> :
                                  <div style={{ height: "400px" }}><div className="srymsg">Sorry, it seems there is no data here for this patient</div></div>
                                }
                              </Card>)}

                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    ""
                  )}

                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.bloodSugarId === 4 ? (
                    <div>
                      {glucoseChartFiler === "Scatter" && (
                        <Card className="linegraphcard">
                          {/* <LineChartDemo /> */}
                          {iglucoseBloodSugarData.length !== 0 ?
                            <LineChartSugarDemo
                              barChartBpGlucoseData={iglucoseBloodSugarData}
                              sugarLevel={sugarLevel}

                            /> :
                            <div style={{ height: "400px" }}><div className="srymsg">Sorry, it seems there is no data here for this patient</div></div>
                          }
                        </Card>
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.bloodSugarId === 4 ? (
                    <div>
                      {glucoseChartFiler === "Table" && (
                        <Card className="tablecard">
                          <div className="preadings">
                            <div className="readingstyle">Patient Readings</div>
                            <DataTableSugarDemo
                              barChartBpGlucoseData={iglucoseBloodSugarData}
                            />
                          </div>
                        </Card>
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {/* -----------------Scatter Ends---------------- */}




















                  {/* -----------------Blood Sugar---------------- */}
                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.bloodSugarId === 2 ? (
                    <Box>
                      <Grid
                        container
                        spacing={2}
                        mt={5}
                        className="blooddiv timeprogress"
                      >
                        <Grid
                          item
                          xl={4}
                          lg={4}
                          md={4}
                          sm={12}
                          xs={12}
                          className="pulse-text"
                        >
                          <div>
                            <div className="maintext">
                              BLOOD SUGAR - iGlucose
                            </div>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xl={8}
                          lg={8}
                          md={8}
                          sm={12}
                          xs={12}
                          className="column-text"
                        >
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={12}
                              sm={12}
                              xs={12}
                              className="monthly-callendar"
                            >
                              <div className="icondiv1">
                                <div className="grapy-section">
                                  <SelectButtonDemo
                                    handleFilterChange={(a) =>
                                      setFilterValue(a)
                                    }
                                  />
                                  {individualpatient?.bloodSugarId === 2 ? (
                                    <div className="selected-option">
                                      <SelectButtonSugarGraphDemo
                                        onGlucoseChartFilterChange={
                                          onGlucoseChartFilterChange
                                        }
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </Grid>
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={12}
                              sm={12}
                              xs={12}
                              className="select-datefield"
                            >
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={3}
                                sm={3}
                                xs={3}
                                className="start-date"
                              >
                                <div className="flex-1 date-overlay">
                                  <div
                                    style={{ color: "#009688" }}
                                    className="eventstart-date"
                                  >
                                    Start Date
                                  </div>
                                  <Calendar
                                    value={sugarStartDate}
                                    onChange={(e) => {
                                      setSugarStartDate(e.value);
                                      setDataItemName("iGlucoseBloodSugar");
                                    }}
                                    placeholder="MM/DD/YYYY"
                                    style={{ width: "100%" }}
                                    maxDate={new Date()}
                                    name="dob"
                                  ></Calendar>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={3}
                                sm={3}
                                xs={3}
                                className="enddate"
                              >
                                <div className="flex-1 date-overlay">
                                  <div
                                    style={{ color: "#009688" }}
                                    className="eventstart-date"
                                  >
                                    End Date
                                  </div>
                                  <Calendar
                                    value={sugarEndDate}
                                    onChange={(e) => setSugarEndDate(e.value)}
                                    placeholder="Enter date"
                                    style={{ width: "100%" }}
                                    name="dob"
                                    maxDate={new Date()}
                                  ></Calendar>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={3}
                                sm={3}
                                xs={3}
                                className="submit-btn"
                              >
                                <div className="flex-1">
                                  <div style={{ color: "#009688" }}></div>

                                  <Button
                                    className="w-100"
                                    label="Submit"
                                    type="submit"
                                    onClick={handleIglucoseBloodSugarFormSubmit}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    ""
                  )}

                  {/* -----------------Scatter Start---------------- */}

                  {individualpatient?.rpm === false && graphFilter === "RPM" &&
                    individualpatient?.bloodSugarId === 2 ? (
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <div className="barchart">
                            {glucoseChartFiler === "Bar" && (
                              <Card className="graphcard">
                                <div>Sorry, it seems there is no data here for this patient.</div>
                              </Card>)}
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    ""
                  )}

                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.bloodSugarId === 2 ? (
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <div className="barchart">
                            {glucoseChartFiler === "Bar" && (
                              <Card className="graphcard">
                                {iglucoseBloodSugarData.length !== 0 ?
                                  <BarChartGluc
                                    barChartBpGlucoseData={iglucoseBloodSugarData}
                                  /> :
                                  <div style={{ height: "400px" }}><div className="srymsg">Sorry, it seems there is no data here for this patient</div></div>
                                }
                              </Card>)}

                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    ""
                  )}

                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.bloodSugarId === 2 ? (
                    <div>
                      {glucoseChartFiler === "Scatter" && (
                        <Card className="linegraphcard">
                          {/* <LineChartDemo /> */}
                          {iglucoseBloodSugarData.length !== 0 ?
                            <LineChartSugarDemo
                              barChartBpGlucoseData={iglucoseBloodSugarData}
                              sugarLevel={sugarLevel}

                            /> :
                            <div style={{ height: "400px" }}><div className="srymsg">Sorry, it seems there is no data here for this patient</div></div>
                          }
                        </Card>
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.bloodSugarId === 2 ? (
                    <div>
                      {glucoseChartFiler === "Table" && (
                        <Card className="tablecard">
                          <div className="preadings">
                            <div className="readingstyle">Patient Readings</div>
                            <DataTableSugarDemo
                              barChartBpGlucoseData={iglucoseBloodSugarData}
                            />
                          </div>
                        </Card>
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {/* -----------------Scatter Ends---------------- */}
                  {/* ------------BLOOD SUGAR - iGLUCOSE ENDS----------------- */}

                  {/* ------------BLOOD SUGAR - Bionomine STARTS----------------- */}

                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.bloodSugarId === 3 ? (
                    <Box className="healthcare">
                      <Grid
                        container
                        spacing={2}
                        className="blooddiv timeprogress"
                      >
                        <Grid
                          item
                          xl={4}
                          lg={4}
                          md={4}
                          sm={12}
                          xs={12}
                          className="pulse-text"
                        >
                          <div>
                            <div className="maintext">
                              BLOOD SUGAR - Bionomine
                            </div>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xl={8}
                          lg={8}
                          md={8}
                          sm={12}
                          xs={12}
                          className="column-text"
                        >
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={12}
                              sm={12}
                              xs={12}
                              className="monthly-callendar"
                            >
                              <div className="icondiv1">
                                <div className="grapy-section">
                                  <SelectButtonDemo
                                    handleFilterChange={(a) => {
                                      setFilterValue(a);
                                      setDataItemName("BionimeBloodSugar");
                                    }}
                                  />
                                  {individualpatient?.bloodSugarId === 3 ? (
                                    <div className="selected-option">
                                      {/* <SelectButtonGraphDemo */}
                                      <SelectButtonSugarGraphDemo
                                        onGlucoseChartFilterChange={
                                          onGlucoseChartFilterChange
                                        }
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </Grid>
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={12}
                              sm={12}
                              xs={12}
                              className="select-datefield"
                            >
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={3}
                                sm={3}
                                xs={3}
                                className="start-date"
                              >
                                <div className="flex-1 date-overlay">
                                  <div
                                    style={{ color: "#009688" }}
                                    className="eventstart-date"
                                  >
                                    Start Date 
                                  </div>
                                  <Calendar
                                    value={sugarStartDate}
                                    onChange={(e) => setSugarStartDate(e.value)}
                                    placeholder="MM/DD/YYY"
                                    style={{ width: "100%" }}
                                    name="dob"
                                    maxDate={new Date()}
                                  ></Calendar>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={3}
                                sm={3}
                                xs={3}
                                className="enddate"
                              >
                                <div className="flex-1 date-overlay">
                                  <div
                                    style={{ color: "#009688" }}
                                    className="eventstart-date"
                                  >
                                    End Date
                                  </div>
                                  <Calendar
                                    value={sugarEndDate}
                                    onChange={(e) => setSugarEndDate(e.value)}
                                    placeholder="MM/DD/YYYY"
                                    style={{ width: "100%" }}
                                    name="dob"
                                    maxDate={new Date()}
                                  ></Calendar>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={3}
                                sm={3}
                                xs={3}
                                className="submit-btn"
                              >
                                <div className="flex-1">
                                  <div style={{ color: "#009688" }}></div>

                                  <Button
                                    label="Submit"
                                    type="submit"
                                    onClick={
                                      handleBloodSugarBionomineFormSubmit
                                    }
                                    className="w-100"
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    ""
                  )}

                  {/* -----------------Scatter Start---------------- */}

                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.bloodSugarId === 3 ? (
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <div className="barchart">
                            {glucoseChartFiler === "Bar" && (
                              <Card className="graphcard">
                                {bloodSugarBionimeData.length !== 0 ?
                                  <BarChartGluc
                                    barChartBpGlucoseData={bloodSugarBionimeData}
                                  /> :
                                  <div style={{ height: "400px" }}><div className="srymsg">Sorry, it seems there is no data here for this patient</div></div>}
                              </Card>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    ""
                  )}

                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.bloodSugarId === 3 ? (
                    <div>
                      {glucoseChartFiler === "Scatter" && (
                        <Card className="linegraphcard">
                          {bloodSugarBionimeData.length !== 0 ?
                            <LineChartSugarDemo
                              barChartBpGlucoseData={bloodSugarBionimeData}
                              sugarLevel={sugarLevel}
                            /> :
                            <div style={{ height: "400px" }}><div className="srymsg">Sorry, it seems there is no data here for this patient</div></div>}
                        </Card>

                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.bloodSugarId === 3 ? (
                    <div>
                      {glucoseChartFiler === "Table" && (
                        <Card className="tablecard">
                          <div className="preadings">
                            <div className="readingstyle">Patient Readings</div>
                            <DataTableSugarDemo
                              barChartBpGlucoseData={bloodSugarBionimeData}
                            />
                          </div>
                        </Card>
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {/* -----------------Scatter Ends---------------- */}
                  {/* ----------------BLOOD SUGAR - BIONOMINE ------------ */}





                  {/* -------------Blood Pressure----------------- */}
                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.bloodPressureId === 2 ? (
                    <Box>
                      <Grid
                        container
                        spacing={2}
                        className="blooddiv timeprogress"
                      >
                        <Grid
                          item
                          xl={4}
                          lg={4}
                          md={4}
                          sm={12}
                          xs={12}
                          className="pulse-text"
                        >
                          <div>
                            <div className="maintext">
                              BLOOD PRESSURE - Body Trace
                            </div>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xl={8}
                          lg={8}
                          md={8}
                          sm={12}
                          xs={12}
                          className="column-text"
                        >
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={12}
                              sm={12}
                              xs={12}
                              className="monthly-callendar"
                            >
                              <div className="icondiv1">
                                {/* <div style={{ marginRight: "0em" }}>
                  <SelectButtonMaxDemo />
                </div> */}
                                <div className="grapy-section">
                                  <SelectButtonDemo
                                    handleFilterChange={(a) => {
                                      setFilterValue(a);
                                      setDataItemName("BodyTraceBloodPressure");
                                    }}
                                  />
                                  {individualpatient?.bloodPressureId === 2 ? (
                                    <div className="selected-option">
                                      <SelectBPButtonGraphDemo
                                        onBPChartFilterChange={
                                          onBPChartFilterChange
                                        }
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </Grid>

                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={12}
                              sm={12}
                              xs={12}
                              className="select-datefield"
                            >
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={3}
                                sm={3}
                                xs={3}
                                className="start-date"
                              >
                                <div className="flex-1 date-overlay">
                                  <div
                                    style={{ color: "#009688" }}
                                    className="eventstart-date"
                                  >
                                    Start Date
                                  </div>
                                  <Calendar
                                    value={bodyTraceBpStartDate}
                                    onChange={(e) =>
                                      setBodyTraceBpStartDate(e.value)
                                    }
                                    placeholder="MM/DD/YYYY"
                                    style={{ width: "100%" }}
                                    name="dob"
                                    maxDate={new Date()}
                                  ></Calendar>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={3}
                                sm={3}
                                xs={3}
                                className="enddate"
                              >
                                <div className="flex-1 date-overlay">
                                  <div
                                    style={{ color: "#009688" }}
                                    className="eventstart-date"
                                  >
                                    End Date
                                  </div>
                                  <Calendar
                                    value={bodyTraceBpEndDate}
                                    onChange={(e) =>
                                      setBodyTraceBpEndDate(e.value)
                                    }
                                    placeholder="Enter date"
                                    style={{ width: "100%" }}
                                    //readOnlyInput={true}
                                    name="dob"
                                    maxDate={new Date()}
                                    mask="99/99/9999"
                                  ></Calendar>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={3}
                                sm={3}
                                xs={3}
                                className="submit-btn"
                              >
                                <div className="flex-1">
                                  <div style={{ color: "#009688" }}></div>

                                  <Button
                                    className="w-100"
                                    label="Submit"
                                    type="submit"
                                    onClick={handleBodyTraceBpFormSubmit}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    ""
                  )}





                  

                  {/* ----------Blood pressure - body trace ends-------------------             */}
                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.bloodPressureId === 3 ? (
                    <Box>
                      <Grid
                        container
                        spacing={2}
                        className="blooddiv timeprogress"
                      >
                        <Grid
                          item
                          xl={4}
                          lg={4}
                          md={4}
                          sm={12}
                          xs={12}
                          className="pulse-text"
                        >
                          <div>
                            <div className="maintext">
                              BLOOD PRESSURE - Omron
                            </div>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xl={8}
                          lg={8}
                          md={8}
                          sm={12}
                          xs={12}
                          className="column-text"
                        >
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={12}
                              sm={12}
                              xs={12}
                              className="monthly-callendar"
                            >
                              <div className="icondiv1">
                                {/* <div style={{ marginRight: "0em" }}>
                  <SelectButtonMaxDemo />
                </div> */}
                                <div className="grapy-section">
                                  <SelectButtonDemo
                                    handleFilterChange={(a) => {
                                      setFilterValue(a);

                                      setDataItemName("OmronBloodPressure");
                                    }}
                                  />
                                  {individualpatient?.bloodPressureId === 3 ? (
                                    <div className="selected-option">
                                      <SelectBPButtonGraphDemo
                                        onBPChartFilterChange={
                                          onBPChartFilterChange
                                        }
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </Grid>
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={12}
                              sm={12}
                              xs={12}
                              className="select-datefield"
                            >
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={3}
                                sm={3}
                                xs={3}
                                className="start-date"
                              >
                                <div className="flex-1 date-overlay">
                                  <div
                                    style={{ color: "#009688" }}
                                    className="eventstart-date"
                                  >
                                    Start Date
                                  </div>
                                  <Calendar
                                    value={IGlucoseStartDate}
                                    onChange={(e) =>{setIGlucoseStartDate(e.value); }}
                                    placeholder="MM/DD/YYYY"
                                    style={{ width: "100%" }}
                                    name="dob"
                                    maxDate={new Date()}
                                  ></Calendar>  
                                </div>
                              </Grid>
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={3}
                                sm={3}
                                xs={3}
                                className="enddate"
                              >
                                <div className="flex-1 date-overlay">
                                  <div
                                    style={{ color: "#009688" }}
                                    className="eventstart-date"
                                  >
                                    End Date
                                  </div>
                                    <Calendar
                                      value={IGlucoseEndDate}
                                      onChange={(e) =>
                                        setIGlucoseEndDate(e.value)
                                      }
                                      placeholder="MM/DD/YYYY"
                                      style={{ width: "100%" }}
                                      name="dob"
                                      maxDate={new Date()}
                                      //mask="99/99/9999"
                                    ></Calendar>
                                   
                                </div>
                              </Grid>
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={3}
                                sm={3}
                                xs={3}
                                className="submit-btn"
                              >
                                <div className="flex-1">
                                  <div style={{ color: "#009688" }}></div>

                                  <Button
                                    className="w-100"
                                    label="Submit"
                                    type="submit"
                                    onClick={handleBloodPressureOmronFormSubmit}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    ""
                  )}




                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.bloodPressureId === 4 ? (
                    <Box className="healthcare">
                      <Grid
                        container
                        spacing={2}
                        className="blooddiv timeprogress"
                      >
                        <Grid
                          item
                          xl={4}
                          lg={4}
                          md={4}
                          sm={12}
                          xs={12}
                          className="pulse-text"
                        >
                          <div>
                            <div className="maintext">
                              BLOOD PRESSURE - iGlucose
                            </div>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xl={8}
                          lg={8}
                          md={8}
                          sm={12}
                          xs={12}
                          className="column-text"
                        >
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={12}
                              sm={12}
                              xs={12}
                              className="monthly-callendar"
                            >
                              <div className="icondiv1">
                                {/* <div style={{ marginRight: "0em" }}>
                  <SelectButtonMaxDemo />
                </div> */}
                                <div className="grapy-section">
                                  <SelectButtonDemo
                                    handleFilterChange={(a) => {
                                      setFilterValue(a);
                                      setDataItemName("iGlucoseBloodPressure");
                                    }}
                                  />
                                  {individualpatient?.bloodPressureId === 4 ? (
                                    <div className="selected-option">
                                      <SelectBPButtonGraphDemo
                                        onBPChartFilterChange={
                                          onBPChartFilterChange
                                        }
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </Grid>
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={12}
                              sm={12}
                              xs={12}
                              className="select-datefield"
                            >
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={3}
                                sm={3}
                                xs={3}
                                className="start-date"
                              >
                                <div className="flex-1 date-overlay">
                                  <div
                                    style={{ color: "#009688" }}
                                    className="eventstart-date"
                                  >
                                    Start Date
                                  </div>
                                  <Calendar
                                    value={IGlucoseStartDate}
                                   
                                    onChange={(e) =>
                                      setIGlucoseStartDate(e.value)
                                    
                                    }
                                    placeholder="MM/DD/YYYY"
                                    style={{ width: "100%" }}
                                    name="dob"
                                    maxDate={new Date()}
                                  ></Calendar>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={3}
                                sm={3}
                                xs={3}
                                className="enddate"
                              >
                                <div className="flex-1 date-overlay">
                                  <div
                                    style={{ color: "#009688" }}
                                    className="eventstart-date"
                                  >
                                    End Date
                                  </div>
                                  <Calendar
                                    value={IGlucoseEndDate}
                                    onChange={(e) =>
                                      setIGlucoseEndDate(e.value)
                                    }
                                    placeholder="MM/DD/YYYY"
                                    style={{ width: "100%" }}
                                    name="dob"
                                    maxDate={new Date()}
                                  ></Calendar>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={3}
                                sm={3}
                                xs={3}
                                className="submit-btn"
                              >
                                <div className="flex-1">
                                  <div style={{ color: "#009688" }}></div>

                                  <Button
                                    className="w-100"
                                    label="Submit"
                                    type="submit"
                                    onClick={
                                      handleBloodPressureIglucoseFormSubmit
                                    }
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    " "
                  )}







                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.bloodPressureId === 5 ? (
                      <Box className="healthcare">
                        <Grid
                          container
                          spacing={2}
                          className="blooddiv timeprogress"
                        >
                          <Grid
                            item
                            xl={4}
                            lg={4}
                            md={4}
                            sm={12}
                            xs={12}
                            className="pulse-text"
                          >
                            <div>
                              <div className="maintext">
                                BLOOD PRESSURE - Continua
                              </div>
                            </div>
                          </Grid>
                          <Grid
                            item
                            xl={8}
                            lg={8}
                            md={8}
                            sm={12}
                            xs={12}
                            className="column-text"
                          >
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xl={6}
                                lg={6}
                                md={12}
                                sm={12}
                                xs={12}
                                className="monthly-callendar"
                              >
                                <div className="icondiv1">
                                  <div className="grapy-section">
                                    <SelectButtonDemo
                                      handleFilterChange={(a) => {
                                        setFilterValue(a);
                                        setDataItemName("ContinuaBloodPressure");
                                      }}
                                    />
                                    {individualpatient?.bloodPressureId === 5 ? (
                                      <div className="selected-option">
                                        <SelectBPButtonGraphDemo
                                          onBPChartFilterChange={onBPChartFilterChange}
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xl={6}
                                lg={6}
                                md={12}
                                sm={12}
                                xs={12}
                                className="select-datefield"
                              >
                                <Grid
                                  item
                                  xl={3}
                                  lg={3}
                                  md={3}
                                  sm={3}
                                  xs={3}
                                  className="start-date"
                                >
                                  <div className="flex-1 date-overlay">
                                    <div
                                      style={{ color: "#009688" }}
                                      className="eventstart-date"
                                    >
                                      Start Date
                                    </div>
                                    <Calendar
                                      value={IGlucoseStartDate}
                                      onChange={(e) =>
                                        setIGlucoseStartDate(e.value)
                                      }
                                      placeholder="MM/DD/YYYY"
                                      style={{ width: "100%" }}
                                      name="dob"
                                      maxDate={new Date()}
                                    ></Calendar>
                                  </div>
                                </Grid>
                                <Grid
                                  item
                                  xl={3}
                                  lg={3}
                                  md={3}
                                  sm={3}
                                  xs={3}
                                  className="enddate"
                                >
                                  <div className="flex-1 date-overlay">
                                    <div
                                      style={{ color: "#009688" }}
                                      className="eventstart-date"
                                    >
                                      End Date
                                    </div>
                                    <Calendar
                                      value={IGlucoseEndDate}
                                      onChange={(e) =>
                                        setIGlucoseEndDate(e.value)
                                      }
                                      placeholder="MM/DD/YYYY"
                                      style={{ width: "100%" }}
                                      name="dob"
                                      maxDate={new Date()}
                                    ></Calendar>
                                  </div>
                                </Grid>
                                <Grid
                                  item
                                  xl={3}
                                  lg={3}
                                  md={3}
                                  sm={3}
                                  xs={3}
                                  className="submit-btn"
                                >
                                  <div className="flex-1">
                                    <div style={{ color: "#009688" }}></div>
                                    <Button
                                      className="w-100"
                                      label="Submit"
                                      type="submit"
                                      onClick={
                                        handleBloodPressureContinuaFormSubmit
                                      }
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    ) : (
                      " "
                    )}





                  {/* -----------------Scatter Start---------------- */}
                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.bloodPressureId === 2 ? (
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <div className="barchart">
                            {bpChartFiler === "Bar" && (
                              <Card className="graphcard">
                                {bodyTraceBpData.length !== 0 ?
                                  <BarChartbpiGlucose
                                    barChartBpGlucoseData={bodyTraceBpData}
                                  /> :
                                  <div style={{ height: "400px" }}><div className="srymsg">Sorry, it seems there is no data here for this patient</div></div>}
                              </Card>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    ""
                  )}

                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.bloodPressureId === 2 ? (
                    <div>
                      {bpChartFiler === "Scatter" && (
                        <Card className="linegraphcard">
                          {bodyTraceBpData.length !== 0 ?
                            <LineChartDemo
                              barChartBpGlucoseData={bodyTraceBpData}
                            /> :
                            <div style={{ height: "400px" }}><div className="srymsg">Sorry, it seems there is no data here for this patient</div></div>}
                        </Card>
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.bloodPressureId === 2 ? (
                    <div>
                      {bpChartFiler === "Table" && (
                        <Card className="tablecard">
                          <div className="preadings">
                            <div className="readingstyle">Patient Readings</div>
                            <DataTableBasicDemo
                              barChartBpGlucoseData={bodyTraceBpData}
                            />
                          </div>
                        </Card>
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {/* -----------------Scatter Ends---------------- */}










                  {/* -----------------Scatter Start---------------- */}

                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.bloodPressureId === 3 ? (
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <div className="barchart">
                            {bpChartFiler === "Bar" && (
                              <Card className="graphcard">
                                {bloodPressureOmronData.length !== 0 ?
                                  <BarChartOmronDemo
                                    barChartBpGlucoseData={bloodPressureOmronData}
                                  /> :
                                  <div style={{ height: "400px" }}><div className="srymsg">Sorry, it seems there is no data here for this patient</div></div>}
                              </Card>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    ""
                  )}

                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.bloodPressureId === 3 ? (
                    <div>
                      {bpChartFiler === "Scatter" && (
                        <Card className="linegraphcard">
                          {bloodPressureOmronData.length !== 0 ?
                            <LineChartDemo
                              barChartBpGlucoseData={bloodPressureOmronData}
                            /> :
                            <div style={{ height: "400px" }}><div className="srymsg">Sorry, it seems there is no data here for this patient</div></div>}
                        </Card>
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.bloodPressureId === 3 ? (
                    <div>
                      {bpChartFiler === "Table" && (
                        <Card className="tablecard">
                          <div className="preadings">
                            <div className="readingstyle">Patient Readings</div>
                            <DataTableBasicDemo
                              barChartBpGlucoseData={bloodPressureOmronData}
                            />
                          </div>
                        </Card>
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {/* -----------------Scatter Ends---------------- */}











                  {/* -----------------Scatter Start---------------- */}

                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.bloodPressureId === 4 ? (
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <div className="barchart">
                            {bpChartFiler === "Bar" && (
                              <Card className="graphcard">
                                {/* <BarChartbpiGlucose /> */}
                                {bloodPressureIglucoseData.length !== 0 ?
                                  <BarChartbpiGlucose
                                    barChartBpGlucoseData={
                                      bloodPressureIglucoseData
                                    }
                                  /> :
                                  <div style={{ height: "400px" }}><div className="srymsg">Sorry, it seems there is no data here for this patient</div></div>}
                              </Card>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    ""
                  )}

                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.bloodPressureId === 4 ? (
                    <div>
                      {bpChartFiler === "Scatter" && (
                        <Card className="linegraphcard">
                          {/* <LineChartDemo /> */}
                          {bloodPressureIglucoseData.length !== 0 ?
                            <LineChartDemo
                              barChartBpGlucoseData={bloodPressureIglucoseData}
                            /> :
                            <div style={{ height: "400px" }}><div className="srymsg">Sorry, it seems there is no data here for this patient</div></div>}
                        </Card>
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.bloodPressureId === 4 ? (
                    <div>
                      {bpChartFiler === "Table" && (
                        <Card className="tablecard">
                          <div className="preadings">
                            <div className="readingstyle">Patient Readings</div>
                            {/* <DataTableBasicDemo /> */}
                            <DataTableBasicDemo
                              barChartBpGlucoseData={bloodPressureIglucoseData}
                            />
                          </div>
                        </Card>
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {/* -----------------Scatter Ends---------------- */}
                  {/* -----------------Blood Pressure - Continua ---------------- */}
                  {/* -----------------Scatter Start---------------- */}
                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.bloodPressureId === 5 ? (
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <div className="barchart">
                            {bpChartFiler === "Bar" && (
                              <Card className="graphcard">
                                {/* <BarChartbpiGlucose /> */}
                                {bloodPressureContinuaData.length !== 0 ?
                                  <BarChartbpiGlucose
                                    barChartBpGlucoseData={
                                      bloodPressureContinuaData
                                    }
                                  /> :
                                  <div style={{ height: "400px" }}><div className="srymsg">Sorry, it seems there is no data here for this patient</div></div>}
                              </Card>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    ""
                  )}

                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.bloodPressureId === 5 ? (
                    <div>
                      {bpChartFiler === "Scatter" && (
                        <Card className="linegraphcard">
                          {/* <LineChartDemo /> */}
                          {bloodPressureContinuaData.length !== 0 ?
                            <LineChartDemo
                              barChartBpGlucoseData={bloodPressureContinuaData}
                            /> :
                            <div style={{ height: "400px" }}><div className="srymsg">Sorry, it seems there is no data here for this patient</div></div>}
                        </Card>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.bloodPressureId === 5 ? (
                    <div>
                      {bpChartFiler === "Table" && (
                        <Card className="tablecard">
                          <div className="preadings">
                            <div className="readingstyle">Patient Readings</div>
                            {/* <DataTableBasicDemo /> */}
                            <DataTableBasicDemo
                              barChartBpGlucoseData={bloodPressureContinuaData}
                            />
                          </div>
                        </Card>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
















                  {/* -----------------Scatter Ends---------------- */}
                  {/* -----------------Weight Scale---------------- */}
                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.weightScaleId === 2 ? (
                    <Box>
                      <Grid
                        container
                        spacing={2}
                        className="blooddiv timeprogress"
                      >
                        <Grid
                          item
                          xl={4}
                          lg={4}
                          md={4}
                          sm={12}
                          xs={12}
                          className="pulse-text"
                        >
                          <div>
                            <div className="maintext">
                              WEIGHT SCALE - Body Trace
                            </div>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xl={8}
                          lg={8}
                          md={8}
                          sm={12}
                          xs={12}
                          className="column-text"
                        >
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={12}
                              sm={12}
                              xs={12}
                              className="monthly-callendar"
                            >
                              <div className="icondiv1">
                                {/* <div style={{ marginRight: "0em" }}>
                  <SelectButtonMaxDemo />
                </div> */}
                                <div className="grapy-section">
                                  <SelectButtonDemo
                                    handleFilterChange={(a) => {
                                      setFilterValue(a);
                                      setDataItemName("BodytraceWeight");
                                    }}
                                  />
                                  {individualpatient?.weightScaleId === 2 ? (
                                    <div className="selected-option">
                                      <SelectWeightButtonGraphDemo
                                        onWeightChartFilterChange={
                                          onWeightChartFilterChange
                                        }
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </Grid>

                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={12}
                              sm={12}
                              xs={12}
                              className="select-datefield"
                            >
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={3}
                                sm={3}
                                xs={3}
                                className="start-date"
                              >
                                <div className="flex-1 date-overlay">
                                  <div
                                    style={{ color: "#009688" }}
                                    className="eventstart-date"
                                  >
                                    Start Date
                                  </div>
                                  <Calendar
                                    value={bodyWeightStartDate}
                                    onChange={(e) =>
                                      setBodyWeightStartDate(e.value)
                                    }
                                    placeholder="MM/DD/YYYY"
                                    style={{ width: "100%" }}
                                    name="dob"
                                    maxDate={new Date()}
                                  ></Calendar>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={3}
                                sm={3}
                                xs={3}
                                className="enddate"
                              >
                                <div className="flex-1 date-overlay">
                                  <div
                                    style={{ color: "#009688" }}
                                    className="eventstart-date"
                                  >
                                    End Date
                                  </div>
                                  <Calendar
                                    value={bodyWeightEndDate}
                                    onChange={(e) =>
                                      setBodyWeightEndDate(e.value)
                                    }
                                    placeholder="Enter date"
                                    style={{ width: "100%" }}
                                    //readOnlyInput={true}
                                    name="dob"
                                    maxDate={new Date()}
                                    //mask="99/99/9999"
                                  ></Calendar>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={3}
                                sm={3}
                                xs={3}
                                className="submit-btn"
                              >
                                <div className="flex-1">
                                  <div style={{ color: "#009688" }}></div>

                                  <Button
                                    className="w-100"
                                    label="Submit"
                                    type="submit"
                                    onClick={handleBodyWeightFormSubmit}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    ""
                  )}
                  {/* -----------------Scatter Start---------------- */}

                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.weightScaleId === 2 ? (
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <div className="barchart">
                            {weightChartFiler === "Bar" && (
                              <Card className="graphcard">
                                {bodyWeightData.length !== 0 ?
                                  <BarChartWeightDemo
                                    barChartBpGlucoseData={bodyWeightData}
                                  /> :
                                  <div style={{ height: "400px" }}><div className="srymsg">Sorry, it seems there is no data here for this patient</div></div>}
                              </Card>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    ""
                  )}

                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.weightScaleId === 2 ? (
                    <div>
                      {weightChartFiler === "Scatter" && (
                        <Card className="linegraphcard">
                          {bodyWeightData.length !== 0 ?
                            <LineChartWeightDemo
                              barChartBpGlucoseData={bodyWeightData}
                            />
                            :
                            <div style={{ height: "400px" }}><div className="srymsg">Sorry, it seems there is no data here for this patient</div></div>}
                        </Card>
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.weightScaleId === 2 ? (
                    <div>
                      {weightChartFiler === "Table" && (
                        <Card className="tablecard">
                          <div className="preadings">
                            <div className="readingstyle">Patient Readings</div>
                            <DataTableWeightDemo
                              barChartBpGlucoseData={bodyWeightData}
                            />
                          </div>
                        </Card>
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {/* -----------------Scatter Ends---------------- */}
                  {/* -----------------End Weight Scale------------ */}
                  {/* -----------------Pluse OXD---------------- */}
                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.pulseOXId === 2 ? (
                    <div style={{ marginTop: "20px" }}>
                      <Box>
                        <Grid
                          container
                          spacing={2}
                          className="blooddiv timeprogress"
                        >
                          <Grid
                            item
                            xl={4}
                            lg={4}
                            md={4}
                            sm={12}
                            xs={12}
                            className="pulse-text"
                          >
                            <div>
                              <div className="maintext">
                                PULSE OX - iGlucose
                              </div>
                            </div>
                          </Grid>
                          <Grid
                            item
                            xl={8}
                            lg={8}
                            md={8}
                            sm={12}
                            xs={12}
                            className="column-text"
                          >
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xl={6}
                                lg={6}
                                md={12}
                                sm={12}
                                xs={12}
                                className="monthly-callendar"
                              >
                                <div className="icondiv1">
                                  {/* <div style={{ marginRight: "0em" }}>
                  <SelectButtonMaxDemo />
                </div> */}
                                  <div className="grapy-section">
                                    <SelectButtonDemo
                                      handleFilterChange={(a) => {
                                        setFilterValue(a);
                                        setDataItemName("iGlucosePulseOX");
                                      }}
                                    />
                                    {individualpatient?.pulseOXId === 2 ? (
                                      <div className="selected-option">
                                        <SelectPulseButtonGraphDemo
                                          onPulseChartFilterChange={
                                            onPulseChartFilterChange
                                          }
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xl={6}
                                lg={6}
                                md={12}
                                sm={12}
                                xs={12}
                                className="select-datefield"
                              >
                                <Grid
                                  item
                                  xl={3}
                                  lg={3}
                                  md={3}
                                  sm={3}
                                  xs={3}
                                  className="start-date"
                                >
                                  <div className="flex-1 date-overlay">
                                    <div
                                      style={{ color: "#009688" }}
                                      className="eventstart-date"
                                    >
                                      Start Date
                                    </div>
                                    <Calendar
                                      value={pulseStartDate}
                                      onChange={(e) =>
                                        setPulseStartDate(e.value)
                                      }
                                      placeholder="MM/DD/YYYY"
                                      style={{ width: "100%" }}
                                      name="dob"
                                      maxDate={new Date()}
                                    ></Calendar>
                                  </div>
                                </Grid>
                                <Grid
                                  item
                                  xl={3}
                                  lg={3}
                                  md={3}
                                  sm={3}
                                  xs={3}
                                  className="enddate"
                                >
                                  <div className="flex-1 date-overlay">
                                    <div
                                      style={{ color: "#009688" }}
                                      className="eventstart-date"
                                    >
                                      End Date
                                    </div>
                                    <Calendar
                                      value={pulseEndDate}
                                      onChange={(e) => setPulseEndDate(e.value)}
                                      placeholder="Enter date"
                                      style={{ width: "100%" }}
                                      name="dob"
                                      maxDate={new Date()}
                                    ></Calendar>
                                  </div>
                                </Grid>
                                <Grid
                                  item
                                  xl={3}
                                  lg={3}
                                  md={3}
                                  sm={3}
                                  xs={3}
                                  className="submit-btn"
                                >
                                  <div className="flex-1">
                                    <div style={{ color: "#009688" }}></div>

                                    <Button
                                      className="w-100"
                                      label="Submit"
                                      type="submit"
                                      onClick={handlePulseFormSubmit}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* -----------------End Pluse OXD---------------- */}
                  {/* -----------------Scatter Start---------------- */}

                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.pulseOXId === 2 ? (
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <div className="barchart">
                            {pulseChartFilter === "Bar" && (
                              <Card className="graphcard">
                                {/* <BarChartDemo /> */}
                                {pulseData.length !== 0 ?
                                  <BarChartPulseDemo
                                    barChartBpGlucoseData={pulseData}
                                  /> :
                                  <div style={{ height: "400px" }}><div className="srymsg">Sorry, it seems there is no data here for this patient</div></div>}
                              </Card>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    ""
                  )}

                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.pulseOXId === 2 ? (
                    <div>
                      {pulseChartFilter === "Scatter" && (
                        <Card className="linegraphcard">
                          {/* <LineChartDemo /> */}
                          {pulseData.length !== 0 ?
                            <LineChartPulseDemo
                              barChartBpGlucoseData={pulseData}
                            /> :
                            <div style={{ height: "400px" }}><div className="srymsg">Sorry, it seems there is no data here for this patient</div></div>}
                        </Card>
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {individualpatient?.rpm === true &&
                    graphFilter === "RPM" &&
                    individualpatient?.pulseOXId === 2 ? (
                    <div>
                      {pulseChartFilter === "Table" && (
                        <Card className="tablecard">
                          <div className="preadings">
                            <div className="readingstyle">Patient Readings</div>
                            {/* <DataTableBasicDemo /> */}
                            <DataTablePulseDemo
                              barChartBpGlucoseData={pulseData}
                            />
                          </div>
                        </Card>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                {graphFilter === "CCM" && (
                  <div className="seperated">
                    {screenState?.userType != 3 && (
                      <>
                        <div className="updatecardtext">PATIENT UPDATES</div>

                        <Accordion
                          style={{ marginBottom: "1%" }}
                          multiple
                        >
                          <AccordionTab
                            style={{ width: "99%" }}
                            header="PERSONAL INFORMATION"
                            onChange={onSelectListChange}
                            value={selectList}
                            options={list}
                          >
                            <PatientUpdate
                              personalInformation={patientDetails}
                            />
                          </AccordionTab>
                          <AccordionTab
                            style={{ width: "99%", marginLeft: "0.1em" }}
                            header="MEDICATION LIST"
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                marginBottom: "1em",
                              }}
                            >
                              <InputText
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="RX Name"
                              />
                              <span>
                                <InputText
                                  value={dose}
                                  onChange={(e) => setDose(e.target.value)}
                                  placeholder="Dose"
                                />
                              </span>
                              <InputText
                                value={fre}
                                onChange={(e) => setFre(e.target.value)}
                                placeholder="Frequency"
                              />
                              <span>
                                <InputText
                                  value={des}
                                  onChange={(e) => setDes(e.target.value)}
                                  placeholder="Description"
                                />
                              </span>
                              {/* <IoMdAddCircle size={50} onClick={addMedication} style={{ color: "#009688" }}  /> */}
                              {!isUpdating && (
                                    <IoMdAddCircle
                                      size={50}
                                      onClick={addMedicationList}
                                      style={{ color: "#009688", cursor: "pointer" }}
                                    />
                                  )}

                                  {/* Conditionally render doctick */}
                                  {isUpdating && (
                                    <div className="active-img">
                                      <img
                                        className="doctick"
                                        src={etickicon}
                                        alt="tick"
                                        onClick={() => updateMedicationList()}
                                      />
                                      <ToastContainer />
                                    </div>
                                  )}
                            </div>

                            <div>
                              <DataTable
                                className="table-border"
                                value={medicationList}
                                responsiveLayout="scroll"
                                scrollable
                                scrollHeight="400px"
                              >
                                <Column
                                  field="rxnName"
                                  header="RXName"
                                ></Column>
                                <Column field="dose" header="Dose"></Column>
                                <Column
                                  field="frequency"
                                  header="Frequency"
                                ></Column>
                                <Column
                                  field="description"
                                  header="Description"
                                ></Column>
                                 <Column
                                   body={actionBodyMedTemplate}
                                  onClick={() => handleMedRow()}
                                  header="Action"
                                ></Column>
                              </DataTable>
                            </div>
                          </AccordionTab>
                          <AccordionTab
                            style={{ width: "99%", marginLeft: "0.1em" }}
                            header="DIAGNOSIS"
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                marginBottom: "1em",
                              }}
                            >
                              <InputText
                                onChange={(e) => setIcd(e.target.value)}
                                placeholder="ICD 10 Code"
                                value={icd}
                              />
                              <span>
                                <InputText
                                  onChange={(e) => seticddesc(e.target.value)}
                                  placeholder="Description"
                                  value={icddesc}
                                />
                              </span>
                              {!isUpdatingdiagn && (
                              <IoMdAddCircle
                                size={50}
                                onClick={addIcdList}
                                style={{ color: "#009688", cursor: "pointer" }}
                              />)}
                              {isUpdatingdiagn && (
                              <div className="active-img">
                                <img
                                  className="doctick"
                                  src={etickicon}
                                  alt="tick"
                                  onClick={() => updateDiagnosisList()}
                                />
                                <ToastContainer />
                              </div>
                              )}
                            </div>
                            <div>
                              <DataTable
                                className="table-border"
                                value={icdList}
                                responsiveLayout="scroll"
                                scrollable
                                scrollHeight="400px"
                              >
                                <Column
                                  field="icdCode"
                                  header="ICD 10 CODE"
                                ></Column>
                                <Column
                                  field="description"
                                  header="DESCRIPTION"
                                ></Column>
                                   <Column
                                  body={actionBodyDiagnTemplate}
                                  onClick={() => handleDiagnRow()}
                                  header="ACTION"
                                ></Column>
                              </DataTable>
                            </div>
                          </AccordionTab>

                          <AccordionTab
                            style={{ width: "99%", marginLeft: "0.1em" }}
                            header="SYMPTOMS"
                          >
                            <InputText
                              onChange={(e) => setSymt(e.target.value)}
                              style={{ width: "99%" }}
                              placeholder="Description"
                              value={symt}
                            />
                          </AccordionTab>
                          <AccordionTab
                            style={{ width: "99%", marginLeft: "0.1em" }}
                            header="PLAN OF CARE"
                          >
                            <InputText
                              onChange={(e) => setPlan(e.target.value)}
                              style={{ width: "99%" }}
                              placeholder="Description"
                              value={plan}
                            />
                          </AccordionTab>
                          <AccordionTab
                            style={{ width: "99%", marginLeft: "0.1em" }}
                            header="INTERVENTION / EDUCATION"
                          >
                            <InputText
                              onChange={(e) => setInter(e.target.value)}
                              style={{ width: "99%" }}
                              placeholder="Description"
                              value={inter}
                            />
                          </AccordionTab>
                          <AccordionTab
                            style={{ width: "99%", marginLeft: "0.1em" }}
                            header="OUTCOME"
                          >
                            <InputText
                              onChange={(e) => setOut(e.target.value)}
                              style={{ width: "99%" }}
                              placeholder="Description"
                              value={out}
                            />
                          </AccordionTab>
                          <AccordionTab
                            style={{ width: "99%", marginLeft: "0.1em" }}
                            header="GOALS"
                          >
                            <InputText
                              onChange={(e) => setGoal(e.target.value)}
                              style={{ width: "99%" }}
                              placeholder="Description"
                              value={goal}
                            />
                          </AccordionTab>
                          <AccordionTab
                            style={{ width: "99%", marginLeft: "0.1em" }}
                            header="ADDITIONAL NOTES"
                          >
                            <InputText
                              onChange={(e) => setAddnote(e.target.value)}
                              style={{ width: "99%" }}
                              placeholder="Description"
                              value={addnote}
                            />
                          </AccordionTab>
                        </Accordion>

                        {/* <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button onClick={handlePatientDetailsSubmit}>SUBMIT</Button> */}
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            className="submit-btn1"
                            onClick={handlePatientDetailsSubmit}
                          >
                            SUBMIT
                          </Button>
                          <ToastContainer />
                          {/* <Button onClick={() => toggleShow1(!show1)}>CLICK HERE</Button> */}
                        </div>
                      </>
                    )}
                    <>

                      <div className="arrowmoveon">
                        {/* <div className="backwordsection">
                    <img src={backwordicon}></img>
                  </div> */}

                        <div className="dateandtime">
                          {/* <div>{JSON.stringify(dateCreate)}</div> */}
                          <Carousel show={5}>
                            {dateCreate &&
                              dateCreate?.length > 0 &&
                              dateCreate.map((y, index) => (
                                <div className="datelist" key={index}>
                                
                                  <Button
                                    onClick={(e) =>
                                      handleClick(e, y.CreatedDate)
                                    }
                                  >
                                    {moment(y.CreatedDate).format(
                                      "MM-DD-YYYY hh:mm:ss a "
                                    )}
                                  </Button>
                                </div>
                              ))}
                          </Carousel>
                        </div>
                        
                      </div>
                    </>
                    {showDetails && (
                      <>
                    <div style={{ marginTop: "2em" }}>
                      <div
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          marginBottom: "2%",
                          textTransform: "uppercase",
                          textAlign: "center", // Center the text
                        }}
                      >
                        Patient Name: {patientotherDetail[0]?.FullName}
                      </div>
                      <div className="md-dcard" style={{ height: '100%', width: '100%' }}>
                        <div className="row">
                          {/* First Row */}
                          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12">
                            <div>Date Of Birth: 
                              <span className="md-textstyles1">
                                {patientotherDetail ? moment(patientotherDetail[0]?.DOB).format("MM-DD-YYYY") : " "}
                              </span>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12">
                            <div>Date of Enrollment: 
                              <span className="md-textstyles1">
                                {patientotherDetail ? moment(patientotherDetail[0]?.EnrollmentDate).format("MM-DD-YYYY") : " "}
                              </span>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12">
                            <div>Created On: 
                              <span className="md-textstyles1">
                                {patientotherDetail ? moment(patientotherDetail[0]?.OtherDetailsCreatedDate).format("MM-DD-YYYY") : " "}
                              </span>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12">
                            <div>Phone Call With Patient: 
                              <span className="md-textstyles1">
                                {/* Checkbox or status could go here */}
                              </span>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12">
                            <div>Symptoms: 
                              <span className="md-textstyles1">
                                {patientotherDetail[0]?.SymptomsDescription}
                              </span>
                            </div>
                          </div>                          
                          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12">
                            <div>Plan of Care: 
                              <span className="md-textstyles1">
                                {patientotherDetail[0]?.PlaneOfCareDescription}
                              </span>
                            </div>
                          </div>
                          {/* Second Row */}                          
                          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12">
                            <div>Intervention or Education: 
                              <span className="md-textstyles1">
                                {patientotherDetail[0]?.InterventionDescription}
                              </span>
                            </div>
                          </div>                          
                          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12">
                            <div>Additional Notes: 
                              <span className="md-textstyles1">
                                {patientotherDetail[0]?.AddttionalNotes}
                              </span>
                            </div>
                          </div>
                          {/* Third Row */}
                          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12">
                            <div>Outcome: 
                              <span className="md-textstyles1">
                                {patientotherDetail[0]?.OutcomeDescription}
                              </span>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12">
                            <div>Goals: 
                              <span className="md-textstyles1">
                                {patientotherDetail[0]?.GoalsDescription}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                        <div>
                          <div
                            style={{
                              fontSize: "18px",
                              fontWeight: "700",
                              marginBottom: "2%",
                              marginTop: "2%",
                            }}
                          >
                            PATIENT MEDICATIONS
                          </div>
                          <div>
                            <PatientMedications />
                          </div>
                        </div>
                        <div>
                          <div
                            style={{
                              fontSize: "18px",
                              fontWeight: "700",
                              marginBottom: "2%",
                              marginTop: "2%",
                            }}
                          >
                            ICD 10 CODES
                          </div>
                          <div>
                            <IcdTable />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}

                <>
                  {screenState?.userType != 3 && (
                    <>
                      <div className="tabbutton">
                        {/* <div class="grid"> */}
                        <div className="col">
                          <div className="maintext1">DOCTORS NOTES</div>
                        </div>
                        {/* </div> */}
                      </div>
                      <Box>
                        <Grid container spacing={2}>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Card className="inputtextcard">
                              <div className="inputflex">
                                <InputText
                                  className="doctortext"
                                  placeholder="Add New Notes Here:"
                                  name="notes"
                                  onChange={(e) => handleChange(e)}
                                  value={note}
                                />
                                <div className="active-img">
                                  <img
                                    className="doctick"
                                    src={etickicon}
                                    alt="tick"
                                    onClick={() => handleNotes()}
                                  />
                                  <ToastContainer />
                                </div>

                              </div>
                            </Card>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box>
                        <Grid container spacing={2}>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <div className="record-tables">
                              {/* <label>{Notesdata?.fullName}</label> */}
                              <DataTable
                                value={Notesdata}
                                scrollable
                                scrollHeight="480px"
                              >
                                <Column
                                  field="notes"
                                  header="NOTES"
                                  sortable
                                ></Column>
                                <Column
                                  field="createdDate"
                                  header="CREATED DATE"
                                  body={dateTemplate}
                                  sortable
                                ></Column>
                                <Column
                                  field="fullName"
                                  header="CREATED BY"
                                  sortable
                                ></Column>
                                <Column
                                  body={actionBodyTemplate}
                                  header="ACTION"
                                ></Column>
                              </DataTable>
                            </div>
                            <div>

                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  )}

                </>

                {/* <Footer /> */}
              </div>
            </div>
          </div>

        </>
      ) : (
        <>
          <Spinner
            animation="border"
            style={{
              marginTop: "40vh",
              marginLeft: "40vw",
            }}
            variant="success"
          />
        </>
      )}
    </>
  );
}

export default Patientdashboard;







