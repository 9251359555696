import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL, POST } from "../_main/constants";
import { POST_PATIENTALLLIST } from "../_main/actiontype";
import { RefreshToken } from "./RefreshSlice";

export const patientallList = createAsyncThunk(
  POST_PATIENTALLLIST + "/alluser",
  async (params,thunkAPI) => {
    const token = localStorage.getItem("AccessToken");
    const option = {
      method: POST,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },

      body: JSON.stringify(params[1]),
    };
    // console.log(params[0],"active*********************************************");
    let endPoint = params[0] === "all" ? "Patient/alluser" : "Patient/active-list" ; 
    const url = `${API_URL + endPoint}`;

    const response = await fetch(url, option).then((res) => {
      if (res.status == 401) {
        thunkAPI.dispatch(RefreshToken());
      }
      return res.json();
    });

    return response;
  }
);
const PatientalllistSlice = createSlice({
  name: POST_PATIENTALLLIST,
  initialState: {
    patientall: [],
    nextPage: 1,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(patientallList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(patientallList.fulfilled, (state, action) => {
      state.patientall = action.payload;
      state.loading = false;
    });
    builder.addCase(patientallList.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default PatientalllistSlice.reducer;












// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { API_URL, POST } from "../_main/constants";
// import { POST_PATIENTALLLIST } from "../_main/actiontype";
// import { RefreshToken } from "./RefreshSlice";

// export const patientallList = createAsyncThunk(
//   POST_PATIENTALLLIST + "/alluser",
//   async (params,thunkAPI) => {
//     const token = localStorage.getItem("AccessToken");
//     const option = {
//       method: POST,
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },

//       body: JSON.stringify(params),
//     };
//     // console.log(params[0],"active*********************************************");
//     // let endPoint = params[0] === "all" ? "Patient/alluser" : "Patient/active-list" ; 
//     const url = `${API_URL + "Patient/alluser"}`;

//     const response = await fetch(url, option).then((res) => {
//       if (res.status == 401) {
//         thunkAPI.dispatch(RefreshToken());
//       }
//       return res.json();
//     });

//     return response;
//   }
// );
// const PatientalllistSlice = createSlice({
//   name: POST_PATIENTALLLIST,
//   initialState: {
//     patientall: [],
//     nextPage: 1,
//     loading: false,
//   },
//   reducers: {},
//   extraReducers: (builder) => {
//     builder.addCase(patientallList.pending, (state) => {
//       state.loading = true;
//     });
//     builder.addCase(patientallList.fulfilled, (state, action) => {
//       state.patientall = action.payload;
//       state.loading = false;
//     });
//     builder.addCase(patientallList.rejected, (state) => {
//       state.loading = true;
//     });
//   },
// });

// export default PatientalllistSlice.reducer;
