import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL, POST } from "../_main/constants";
import { POST_DOCTORNOTE } from "../_main/actiontype";
import { RefreshToken } from "./RefreshSlice";

export const doctornotes = createAsyncThunk(
  POST_DOCTORNOTE + "/doctornotes",
  async (params, thunkAPI) => {
    const token = localStorage.getItem("AccessToken");
    // console.log("token", token);

    const option = {
      method: POST,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    };

    const url = `${API_URL + "Patient/doctornotes"}`;
    const response = await fetch(url, option).then((res) => {
      if (res.status == 401) {
        thunkAPI.dispatch(RefreshToken());
      }
      return res.json();
    });

    return response;
  }
);

const doctornoteSlice = createSlice({
  name: POST_DOCTORNOTE,
  initialState: {
    doctornotes: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doctornotes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(doctornotes.fulfilled, (state, action) => {
      state.Register = action.payload;
      state.loading = false;
    });
    builder.addCase(doctornotes.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default doctornoteSlice.reducer;
