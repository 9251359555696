/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MdModeEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { patientallList } from "../../Api_Redux/PatientalldetailsSlice";
import PaginatorDemo from "../../Component/Paginator";
import { InputText } from "primereact/inputtext";
import { Paginator } from "primereact/paginator";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import { DASHBOARD_ROUTE } from "../../_main/routeConstants";
import { useNavigate } from "react-router-dom";
import { Checkbox } from 'primereact/checkbox';
import { Button } from "primereact/button";
import Modal from "react-bootstrap/Modal";
import { changeUsers } from "../../Api_Redux/ForgotSlice";
import { activateinactivate } from "../../Api_Redux/ActiveSlice";
import { INDIVIDUALPATIENTDETAILS_ROUTE, INDIVIDUALPATIENTDETAILSS_ROUTE } from "../../_main/routeConstants";
import { ToastContainer, toast } from "react-toastify";
import ResetPassword from "../../asset/Image/ResetPassword.svg";
import { patientId } from "../../Api_Redux/PatientidSlice";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from 'primereact/inputswitch'

function Patienthealth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [totalCount, setTotalCount] = useState("");
  const [customFirst1, setCustomFirst1] = useState(0);
  const [customRows1, setCustomRows1] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const handleresetClose = () => setresetShow(false);
  const [staffId, setStaffId] = useState(0);
  const [patientEmail, setPatientEmail] = useState("");
  const [showreset, setresetShow] = useState(false);
  const [fullName, setFullName] = useState("");
  const [checked, setChecked] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [values, setValues] = useState("")


  const openModal = () => {
    setShowModal(true);
  };

  const handleClose1 = () => {
    setShowModal(false);
  };

  const handleClose2 = () => {
    setSelectedData(null)
    setShowStatusModal(!showStatusModal)
  };

  const allUser = useSelector(
    (state) => state.PatientalllistSlice?.patientall?.data?.activePatientList
  );

  // const activeUser = useSelector(
  //   (state) => {
  //     const totalUser =  state?.PatientalllistSlice?.patientall?.data?.allPatientList
  //     // console.log(totalUser,"//////")
  //     return totalUser ? totalUser.filter((item) =>item?.isActive) : [];

  //   }
  // );



  const handleSearch = (e) => {

    const patientParams = ["active" , {
      pageNo: 1,
      pageSize: 5,
      search: e.target.value,
      sortField: "",
      sortOrder: ""
    }] 
    dispatch(patientallList(patientParams));
  };




  const getPatientList = () => {
    const params = ["active",{
      pageNo: 1,
      pageSize: 10,
      search: "",
      sortField: "",
      sortOrder: ""
    }]
    dispatch(patientallList(params))
      .then((isSuccess) => {
        // console.log("success",isSuccess);
        setShowStatusModal(false)
        if (isSuccess.payload.status == 1) {
          setTotalCount(isSuccess.payload.data.totalCount);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getPatientList();
  }, []);

  const handleActivebutton = () => {
    const params = {
      patientId: selectedData.userId,
      action: !selectedData.isActive,
    };
    dispatch(activateinactivate(params))
      .then((isSuccess) => {
        if (isSuccess.payload.status == 1) {
          toast.success(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }
        getPatientList();
      })
      .catch((error) => {
        alert(error);
      });
  }

  const onCustomPageChange1 = (event) => {
    setCustomFirst1(event.first);
    setCustomRows1(event.rows);
    const patientParams = ["active" ,{
      pageNo: event.page + 1,
      pageSize: event.rows ? event.rows : 5,
      search: "",
      sortField: "",
      sortOrder: ""
    }]
    dispatch(patientallList(patientParams));
  };

  const openResetModal = (rowData) => {
    setFullName(rowData.fullName);
    setPatientEmail(rowData.emailId);
    setresetShow(true);
  };


  const handleResetStaff = () => {
    const params = {
      email: patientEmail,
    };
    dispatch(changeUsers(params))
      .then((isSuccess) => {
        if (isSuccess.payload.status == 1) {
          toast.success(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          setTimeout(() => { }, 1000);
          setresetShow(false);
        } else {
          toast.error(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          setTimeout(() => { }, 1000);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };




  const actionBodyTemplate1 = (rowData, fieldProperties) => {
    return (
      <React.Fragment>
        {/* <Checkbox
          onChange={(e) => { e.stopPropagation(); handleCheckboxChange(e, rowData, fieldProperties.rowIndex) }} checked={rowData.isActive}
        ></Checkbox> */}

<InputSwitch checked={rowData.isActive}  onChange={(e) => { e.stopPropagation(); handleCheckboxChange(e, rowData, fieldProperties.rowIndex) }} />
      </React.Fragment>
    );
  };

  const handleCheckboxChange = (e, data, index, rowData) => {


    e.preventDefault();
    setSelectedData(data)
    setShowStatusModal(true)
  };

  const goback = () => {
    navigate(DASHBOARD_ROUTE);
  };

  const getNameRecords = () => {
    return (
      <React.Fragment>
        <div>
          <div>{allUser[0].fullName}</div>
          <div>{allUser[0].phoneNumber}</div>
          <div>{allUser[0].emailId}</div>
          {/* <div>{activeUser[0].fullName}</div>
          <div>{activeUser[0].phoneNumber}</div>
          <div>{activeUser[0].emailId}</div> */}
        </div>
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (

      <React.Fragment>

        {/* <Tooltip>
          <IconButton><VisibilityIcon onClick={(e) => pnavigateToEdit(rowData)} /></IconButton>
        </Tooltip> */}

        <Tooltip
          title={<p style={{ fontSize: "10px" }}>Reset Password</p>}
          placement="bottom"
          arrow
        >
          <img
            className="ms-reset"
            src={ResetPassword}
            onClick={(e) => { e.stopPropagation(); openResetModal(rowData) }}
          />
        </Tooltip>
      </React.Fragment>
    );
  };

  const representativeBodyTemplate = (rowData) => {
    return (
      <>

        <span onClick={(e) => {
          const param = {
            id: rowData.userId
          }
          dispatch(patientId(param))
          navigate(INDIVIDUALPATIENTDETAILS_ROUTE, { state: e.data })
        }} style={{ color: "#007BFF" }}>{rowData.fullName}</span>
      </>
    );
  };

  const template1 = {
    layout:
      "PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport",
    PrevPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-2">Pre</span>
        </button>
      );
    },
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
       
   
        { label: 5, value: 5 },
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: "100 and above", value: 5000 }
      ];

      return (
        <React.Fragment>
       
          <Dropdown
            
            value={options.value}
         
            options={dropdownOptions}
            onChange={options.onChange}
            placeholder="Choose"
          />
        </React.Fragment>
      );
    },
    NextPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-2">Next</span>
        </button>
      );
    },
  };

  var x = localStorage.getItem("userType");

  const pnavigateToEdit = (user) => {
    const param = {
      id: user.userId
    }
    dispatch(patientId(param))

    navigate(INDIVIDUALPATIENTDETAILS_ROUTE, { state: user });
    { x == 2 && (navigate(INDIVIDUALPATIENTDETAILSS_ROUTE, { state: user })) }
  };



  return (
    <div>

      <Modal show={showreset} onHide={handleresetClose} animation={false}>
        <Modal.Header className='popupclose-btn' closeButton></Modal.Header>
        <Modal.Body >Do you want to reset "{fullName}" password?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleresetClose}>
            No
          </Button>
          <Button variant="primary" onClick={handleResetStaff}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal
        show={showStatusModal}
        onHide={handleClose2}
        animation={false}
      >
        <Modal.Header
          className="popupclose-btn"
          closeButton
        ></Modal.Header>
        <Modal.Body style={{ fontSize: "20px" }}>
          Do You Want to {selectedData?.isActive == false ? 'Activate' : 'InActivate'} User ?
        </Modal.Body>
        <Modal.Footer className="footer-delete">
          <button className="modal-close" variant="secondary" onClick={handleClose2}>
            Close
          </button>
          <Button variant="primary" onClick={handleActivebutton}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>





      <Modal
        show={showModal}
        onHide={handleClose1}
        animation={false}
      >
        <Modal.Header
          className="popupclose-btn"
          closeButton
        ></Modal.Header>
        <Modal.Body style={{ fontSize: "20px" }}>
          Do you want to Delete
        </Modal.Body>
        <Modal.Footer className="footer-delete">
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <div style={{ flexDirection: "row", display: "flex" }}>
        <Typography
          variant="h5"
          style={{ marginTop: "0.6em" }}
          className="header-message"
          onClick={goback}
        >
          <ArrowBackIcon
            style={{ cursor: "pointer" }}
            fontSize={"25"}
          ></ArrowBackIcon>
        </Typography>
        <div className="ml-title">MANAGE PATIENT
          {/* <Checkbox  onChange={e => setChecked(e.checked)} checked={checked} onClick={openModal} /> */}
        </div>
      </div>
      <div style={{ textAlign: "end", marginTop: "-3.5em" }}>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />

          <InputText onChange={handleSearch} placeholder="Search" />
        </span>
      </div>

      <div style={{ marginTop: "10px" }}>
        {/* <InputSwitch checked={rowClick} onChange={(e) => setRowClick(e.value)} /> */}
        <DataTable
          value={allUser}
          // value = {activeUser}
          style={{ cursor: "pointer", fontSize: "13px" }}
        >
          {/* <Column field="fullName" header="PATIENT NAME" sortable></Column> */}
          <Column field="fullName" header="PATIENT NAME" sortable body={representativeBodyTemplate} ></Column>
          {/* <Column field="phoneNumber" header="PHONE NUMBER" sortable></Column> */}
          <Column field="createrName" header="CREATED BY" sortable></Column>
          <Column
            field="bodyTraceIMEI"
            header="BLOODPRESSUREIMEI"
            sortable
          ></Column>
          <Column
            field="iGlucoseBloodPressureIMEI"
            header="IBLOODPRESSUREIMEI"
            sortable
          ></Column>
          <Column
            field="bodyTraceWeightScaleIMEI"
            header="WEIGHTIMEI"
            sortable
          ></Column>
          <Column field="iGlucoseIMEI" header="GLUCOSEIMEI" sortable></Column>
          <Column
            field="iGlucosePulseOxIMEI"
            header="PULSEOXIMEI"
            sortable
          ></Column>
          <Column
            field="bionimeIMEI"
            header="GLUCOSEBIONIMEIMEI"
            sortable
          ></Column>
          {/* <Column
            field="check"
            header="In Activate"
            body={actionBodyTemplate1}
            style={{ justifyContent: "right" }}
          ></Column> */}
          <Column
            field="isActive"
            header="STATUS"
            body={actionBodyTemplate1}
            sortable
          ></Column>
          <Column
            header="ACTION"
            body={actionBodyTemplate}
          >

          </Column>
        </DataTable>
      </div>
      <div className="paginator">
        <Paginator
          template={template1}
          first={customFirst1}
          rows={customRows1}
          totalRecords={totalCount}
          onPageChange={onCustomPageChange1}
        ></Paginator>
      </div>
    </div>
  );
}

export default Patienthealth;













